import {Component, ElementRef, AfterViewInit, ViewChild, Inject, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {ContentService} from './services/content.service';

@Component({
    selector: 'cc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    title = 'Crystal Care';
    schemaOrganisation = {
        '@context' : 'http://schema.org',
        '@type' : 'Organization',
        '@id': 'http://crystalcarezorg.nl',
        'name' : 'Crystal Care Zorg',
        'logo' : 'http://www.crystalcarezorg.nl/assets/images/header_logo.svg',
        'address' : {
            '@type' : 'PostalAddress',
            'streetAddress' : this.contentService.address.street,
            'addressLocality' : this.contentService.address.city,
            'postalCode' : this.contentService.address.zip,
            'telephone' : '075-6151119',
            'email' : 'info@crystalcarezorg.nl'
        },
        'url' : 'http://www.crystalcarezorg.nl/'
    };
    schemaWebSite = {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        '@id': 'http://crystalcarezorg.nl',
        'url': 'http://crystalcarezorg.nl',
        'name': 'Crystal Care Zorg',
        'inLanguage': 'nl'
    };
    @ViewChild('page')
    private page: ElementRef;

    constructor(private routeSnap: Router, @Inject(PLATFORM_ID) private platformId: object, private contentService: ContentService) {
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.routeSnap.events.subscribe(val => {
                if (val instanceof NavigationEnd) {
                    this.page.nativeElement.scrollTop = 0;
                }
            });
        }
    }
}
