import {Component, Input, OnInit} from '@angular/core';
import {ContentService} from '../services/content.service';
import {ActivatedRoute} from '@angular/router';
import {SeoService} from '../services/seo.service';
import {Content} from '../interfaces/content';

@Component({
    selector: 'cc-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
    @Input() content: Content;
    isArray: boolean;

    constructor(private contentService: ContentService, private routeSnap: ActivatedRoute, private seoService: SeoService) {
    }

    ngOnInit() {
        this.routeSnap.url.subscribe(val => {
            switch (val[0].path) {
                case 'verpleging': {
                    this.content = this.contentService.verpleging;
                }
                    break;
                case 'aanvullende-zorg': {
                    this.content = this.contentService.aanvullendeZorg;
                }
                    break;
                case 'begeleiding': {
                    this.content = this.contentService.begeleiding;
                    this.seoService.setTitle('Begeleiding | Crystal Care');
                }
                    break;
                case 'hulp-bij-huishouding': {
                    this.content = this.contentService.hulpBijHuishouding;
                }
                    break;
                case 'persoonlijke-verzorging': {
                    this.content = this.contentService.persoonlijkeVerzorging;
                }
                    break;
                case 'zorgaanvraag': {
                    this.content = this.contentService.zorgAanvraag;
                }
                    break;
                case 'zorgverleners': {
                    this.content = this.contentService.zorgVerleners;
                }
                    break;
                case 'informatie-en-uitleg': {
                    this.content = this.contentService.informatieEnUitleg;
                }
                    break;
                case 'visie-en-missie': {
                    this.content = this.contentService.visieEnMissie;
                }
                    break;
                case 'erkend-leerbedrijf': {
                    this.content = this.contentService.erkendLeerbedrijf;
                }
                    break;
                case 'disclaimer': {
                    this.content = this.contentService.disclaimer;
                }
                    break;
                case 'privacy-verklaring': {
                    this.content = this.contentService.privacyStatement;
                }
                    break;
                case 'klachtenregeling': {
                    this.content = this.contentService.klachtenregeling;
                }
                    break;
                case 'uitsluitingscriteria': {
                    this.content = this.contentService.uitsluitingsCriteria;
                }
            }
            this.isArray = this.content.image instanceof Array;
            let image: string;
            if (!this.isArray) {
                image = this.content.image as string;
            } else {
                image = this.content.failsafe;
            }
            this.seoService.setTitle(this.content.title.replace('<h1>', '').replace('</h1>', '') + ' | Crystal Care');
            this.seoService.generateTags({
                description: this.content.description,
                'og:description': this.content.description,
                'og:image': image ? image : 'http://crystalcarezorg.nl/assets/images/crystalcare-banner-image-small.webp',
                'og:url': 'http://crystalcarezorg.nl/' + val[0].path,
                'og:title': this.content.title.replace('<h1>', '').replace('</h1>', ''),
                'og:locale': 'nl_NL',
                'og:type': 'website',
                'twitter:card': 'summary',
                'twitter:description': this.content.description,
                'twitter:title': this.content.title.replace('<h1>', '').replace('</h1>', ''),
                'twitter:image': image ? image : 'http://crystalcarezorg.nl/assets/images/crystalcare-banner-image-small.webp'
            });
        });
    }

}
