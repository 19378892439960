import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PageComponent} from './page/page.component';
import {HomeComponent} from './home/home.component';
import {VacanciesComponent} from './vacancies/vacancies.component';
import {ContactComponent} from './contact/contact.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';

const routes = [
    {path: '', component: HomeComponent},
    {path: 'begeleiding', component: PageComponent},
    {path: 'hulp-bij-huishouding', component: PageComponent},
    {path: 'persoonlijke-verzorging', component: PageComponent},
    {path: 'verpleging', component: PageComponent},
    {path: 'aanvullende-zorg', component: PageComponent},
    {path: 'zorgaanvraag', component: PageComponent},
    {path: 'zorgverleners', component: PageComponent},
    {path: 'zorgaanbod', component: PageComponent},
    {path: 'informatie-en-uitleg', component: PageComponent},
    {path: 'visie-en-missie', component: PageComponent},
    {path: 'erkend-leerbedrijf', component: PageComponent},
    {path: 'vacatures', component: VacanciesComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'disclaimer', component: PageComponent},
    {path: 'privacy-verklaring', component: PageComponent},
    {path: 'klachtenregeling', component: PageComponent},
    {path: 'uitsluitingscriteria', component: PageComponent},
  {path: '**', pathMatch: 'full', component: PageNotFoundComponent},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })
    ],
    exports: [
        RouterModule
    ],
    providers: [],
    declarations: []
})
export class RoutingModule {
}
