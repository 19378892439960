import {Component, OnInit} from '@angular/core';
import {SeoService} from '../services/seo.service';
import {ContentService} from '../services/content.service';

interface UspItem {
    title: string;
    text: string;
    icon: string;
}

interface PreviewItem {
    title: string;
    image: string | { src: string, type: string }[];
    failsafe: string;
    text: string;
    link: string;
}

@Component({
    selector: 'cc-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public uspItems: UspItem[];
    public previewItems: PreviewItem[];

    constructor(private seoService: SeoService, public contentService: ContentService) {
    }

    ngOnInit() {
        this.createUspItems();
        this.createPreviewItems();
        this.seoService.setTitle('Crystal Care zorgt voor u');
        this.seoService.generateTags({
            description: `‘Zorgt voor u’ staat bij Crystal Care centraal! Bij kunnen dragen aan deze maatschappelijke
                           opdracht inspireert ons. Onze medewerkers zijn warm en betrokken, zij werken vanuit het hart
                           en hebben liefde voor mensen.`,
            'og:description': `Zorgt voor u’ staat bij Crystal Care centraal! Bij kunnen dragen aan deze maatschappelijke
                           opdracht inspireert ons. Onze medewerkers zijn warm en betrokken, zij werken vanuit het hart
                           en hebben liefde voor mensen.`,
            'og:image': 'http://crystalcarezorg.nl/assets/images/crystalcare-banner-image-small.webp',
            'og:url': 'http://crystalcarezorg.nl',
            'og:title': 'Thuiszorg Crystal Care',
            'og:locale': 'nl_NL',
            'og:type': 'website',
            'twitter:card': 'summary',
            'twitter:description': `‘Zorgt voor u’ staat bij Crystal Care centraal! Bij kunnen dragen aan deze maatschappelijke
                           opdracht inspireert ons. Onze medewerkers zijn warm en betrokken, zij werken vanuit het hart
                           en hebben liefde voor mensen.`,
            'twitter:title': 'Thuiszorg Crystal Care',
            'twitter:image': 'http://crystalcarezorg.nl/assets/images/crystalcare-banner-image-small.webp'
        });
    }

    private createPreviewItems() {
        this.previewItems = [
            {
                title: 'Begeleiding',
                image: [
                    {src: 'assets/images/crystalcare-begeleiding-small.webp', type: 'image/webp'},
                    {src: 'assets/images/crystalcare-begeleiding-small.jp2', type: 'image/jp2'},
                    {src: 'assets/images/crystalcare-begeleiding-small.jxr', type: 'image/jxr'}
                ],
                failsafe: 'assets/images/crystalcare-begeleiding-small.jpg',
                text: `<p><i>‘De regie van uw leven in eigen hand hebben.’</i> Wij vinden het belangrijk dat u zelf kunt kiezen.</p>`,
                link: 'begeleiding'
            },
            {
                title: 'Hulp bij huishouding',
                image: [
                    {src: 'assets/images/crystalcare-huishoudelijk-small.webp', type: 'image/webp'},
                    {src: 'assets/images/crystalcare-huishoudelijk-small.jp2', type: 'image/jp2'},
                    {src: 'assets/images/crystalcare-huishoudelijk-small.jxr', type: 'image/jxr'}
                ],
                failsafe: 'assets/images/crystalcare-huishoudelijk-small.jpg',
                text: `<p>Afhankelijk van de situatie thuis, ondersteunt Crystal Care bij huishoudelijke taken.</p>`,
                link: 'hulp-bij-huishouding'
            },
            {
                title: 'Persoonlijke verzorging',
                image: [
                    {src: 'assets/images/crystalcare-persoonlijk-small.webp', type: 'image/webp'},
                    {src: 'assets/images/crystalcare-persoonlijk-small.jp2', type: 'image/jp2'},
                    {src: 'assets/images/crystalcare-persoonlijk-small.jxr', type: 'image/jxr'}
                ],
                failsafe: 'assets/images/crystalcare-persoonlijk-small.jpg',
                text: `<p>Voor het ondersteunen of overnemen van verzorging bij mensen met een aandoening of beperking.</p>`,
                link: 'persoonlijke-verzorging'
            },
            {
                title: 'Verpleging',
                image: [
                    {src: 'assets/images/crystalcare-verpleging-small.webp', type: 'image/webp'},
                    {src: 'assets/images/crystalcare-verpleging-small.jp2', type: 'image/jp2'},
                    {src: 'assets/images/crystalcare-verpleging-small.jxr', type: 'image/jxr'}
                ],
                failsafe: 'assets/images/crystalcare-verpleging-small.jpg',
                text: `<p>Onder wijkverpleging vallen verpleegkundige handelingen die thuis plaatsvinden.</p>`,
                link: 'verpleging'
            },
            {
                title: 'Aanvullende zorg',
                image: [
                    {src: 'assets/images/crystalcare-aanvullendezorg-small.webp', type: 'image/webp'},
                    {src: 'assets/images/crystalcare-aanvullendezorg-small.jp2', type: 'image/jp2'},
                    {src: 'assets/images/crystalcare-aanvullendezorg-small.jxr', type: 'image/jxr'}
                ],
                failsafe: 'assets/images/crystalcare-aanvullendezorg-small.jpg',
                text: `<p>Heeft u lichamelijke klachten? Kunt u zelf uw maaltijden niet meer bereiden?</p>`,
                link: '/aanvullende-zorg'
            },
        ];
    }

    private createUspItems() {
        this.uspItems = [
            {
                title: 'Vrolijk en energiek',
                text: `<p>Wij werken zichtbaar met plezier.</p>
                       <p>Hiermee zijn wij onderscheidend ten opzichte van andere zorgaanbieders.</p>`,
                icon: 'wb_sunny'
            },
            {
                title: 'Bij u thuis',
                text: `<p>Wij leveren zorg aan huis of dicht bij huis.</p>
                       <p>Onze dienstverlening wordt tot 24 uur per dag en 7 dagen per week geleverd.</p>`,
                icon: 'home'
            },
            {
                title: 'Samen met u',
                text: `<p>Wij hanteren een luisterende houding.</p>
                       <p>Om samen met u te zoeken naar wat we kunnen bijdragen aan het gewenste resultaat.</p>`,
                icon: 'people'
            }
        ];
    }

}
