import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {MetaConfig} from '../interfaces/meta-config';

@Injectable()
export class SeoService {

    constructor(private meta: Meta, private titleService: Title) {

    }

    public generateTags(config: MetaConfig): void {
        // noinspection TsLint
        for (const key in config) {
            this.meta.updateTag({name: key, content: config[key]});
        }
    }

    public setTitle(newTitle: string): void {
        this.titleService.setTitle(newTitle);
    }

}
