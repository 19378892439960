import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

interface Links {
  name: string;
  link: string;
}

@Component({
  selector: 'cc-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  public zorgAanbodItems: Links[];
  public overCrystalCareItems: Links[];
  public show: boolean;
  public hide: boolean;

  destroyed = new Subject<void>();
  currentScreenSize: string;

  large = ['Medium', 'Large', 'XLarge'];
  small = ['XSmall', 'Small'];

  // Create a map to display breakpoint names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(breakpointObserver: BreakpointObserver) {
    this.show = false;
    this.hide = true;

    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).pipe(takeUntil(this.destroyed)).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
        }
      }
    });
  }

  toggleMenu() {
    this.show = !this.show;
    this.hide = !this.hide;
  }

  ngOnInit() {
    this.zorgAanbodItems = [
      {name: 'Begeleiding', link: 'begeleiding'},
      {name: 'Hulp bij huishouding', link: 'hulp-bij-huishouding'},
      {name: 'Persoonlijke verzorging', link: 'persoonlijke-verzorging'},
      {name: 'Verpleging', link: 'verpleging'},
      {name: 'Aanvullende zorg', link: 'aanvullende-zorg'}
    ];
    this.overCrystalCareItems = [
      {name: 'Informatie en uitleg', link: 'informatie-en-uitleg'},
      {name: 'Visie en missie', link: 'visie-en-missie'},
      {name: 'Erkend leerbedrijf', link: 'erkend-leerbedrijf'},
      {name: 'Vacatures', link: 'vacatures'},
      {name: 'Contact', link: 'contact'}
    ];
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

}
