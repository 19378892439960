import {Injectable} from '@angular/core';
import {Content} from '../interfaces/content';


@Injectable()
export class ContentService {
  public verpleging: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public aanvullendeZorg: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public begeleiding: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public hulpBijHuishouding: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public persoonlijkeVerzorging: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public zorgAanvraag: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public zorgVerleners: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public informatieEnUitleg: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public visieEnMissie: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public erkendLeerbedrijf: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public disclaimer: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public privacyStatement: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public uitsluitingsCriteria: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public klachtenregeling: Content = {title: '', subtitle: '', body: '', image: '', description: '', failsafe: ''};
  public vacatures: Content[] = [];

  public address = {
    name: ``,
    street: `Terschellingstraat 1`,
    addition: `unit 1D`,
    zip: `1506ZG`,
    city: `Zaandam`
  };

  public kvkNumber = '78464145';

  private vVerzorgendeIG: Content = {
    title: `Verzorgende IG`,
    subtitle: `Crystal Care is op dit moment op zoek naar een Verzorgende IG`,
    body: `
            <h3>Functieomschrijving</h3>
            <p>
                Ben jij in je laatste studiejaar van je verzorgende IG opleiding of heb jij een diploma verzorgende IG
                en vind je het leuk om ervaring op doen of bij te verdienen?
            </p>
            <p>
                Als verzorgende IG heb je een actieve rol in het beantwoorden van ondersteuningsvragen van cliënten.
                Hierbij staat het welzijn en het bevorderen van de (sociale) zelfredzaamheid voorop.
            </p>
            <h3>Functie-eisen</h3>
            <p>
                <ul>
                    <li>Je bent in het bezit van een diploma verzorgende IG;</li>
                    <li>Je hebt affiniteit met de ouderenzorg;</li>
                    <li>Je hebt een flexibele instelling wat betreft werktijden en -locatie;</li>
                    <li>Je bent zelfstandig maar niet bang om vragen te stellen;</li>
                    <li>Je bent in het bezit van een Hepatitis B vaccinatiebewijs;</li>
                    <li>Je bent in het bezit van of bereid een VOG aan te vragen;</li>
                </ul>
            </p>
            <p>
                Geinteresseerd in deze vacature? Stuur dan jou CV* en motivatie door op onderstaande knop te drukken.
            </p>
            <p class="mat-small text-accent">*CV graag meesturen in PDF formaat</p>
        `,
    image: ``,
    failsafe: ``
  };
  private vVerpleegkundige: Content = {
    title: `Verpleegkundige`,
    subtitle: ``,
    body: `
            <h3>Functieomschrijving</h3>
            <p>
                Ben jij een verantwoordelijke, flexibele en ondernemende verpleegkundige en lijkt het je wat om te
                werken in een kleinschalige thuiszorgorganisatie? Neem dan contact op met ons.
            </p>
            <p>
                Als verpleegkundige ben je verantwoordelijk voor het gehele verpleegkundige proces. Je ondersteunt
                patiënten bij de ADL, je bewaakt hun gezondheid, voert verpleegtechnische handelingen uit en je
                observeert en rapporteert je bevindingen.
            </p>
            <h3>Functie-eisen</h3>
            <p>
                <ul>
                    <li>Je bent in het bezit van een diploma verpleegkunde op MBO-4 of HBO niveau;</li>
                    <li>Je bent BIG geregistreerd;</li>
                    <li>Je bent Hepatitis B gevaccineerd;</li>
                    <li>Je hebt een geldige VOG verklaring of je bent bereidt deze aan te vragen;</li>
                    <li>Je kunt zowel zelfstandig als in teamverband werken;</li>
                </ul>
            </p>
            <p>
                Geinteresseerd in deze vacature? Stuur dan jou CV* en motivatie door op onderstaande knop te drukken.
            </p>
            <p class="mat-small text-accent">*CV graag meesturen in PDF formaat</p>
        `,
    image: '',
    failsafe: ``
  };
  private vHelpende: Content = {
    title: `Helpende of Helpende 2 plus`,
    subtitle: ``,
    body: `
            <h3>Functieomschrijving</h3>
            <p>
                Als helpende zorg je voor de lichamelijke verzorging van cliënten zoals bijvoorbeeld wassen en aankleden.
                Je reikt medicatie aan, doet wond-, stomaverzorging en ben je in staat te zwachtelen. Je bent
                cliëntvriendelijk, initiatiefrijk en kunt zowel zelfstandig als in teamverband goed functioneren.
            </p>
            <h3>Functie-eisen</h3>
            <p>
                In de eerste plaats vragen wij jouw betrokkenheid voor het leveren van comfortabele zorg aan de
                cliënten in de wijk. Zij staan centraal. Daarnaast beschik je over een diploma Helpende of Helpende 2
                plus en heb je ervaring in de ouderenzorg.
            </p>
            <p>
                Geinteresseerd in deze vacature? Stuur dan jou CV* en motivatie door op onderstaande knop te drukken.
            </p>
            <p class="mat-small text-accent">*CV graag meesturen in PDF formaat</p>
        `,
    image: '',
    failsafe: ``
  };
  private vHuishoudelijkeHulp: Content = {
    title: `Huishoudelijke hulp`,
    subtitle: ``,
    body: `
            <p>Crystal Care is op dit moment op zoek naar een Huishoudelijke hulp.</p>
            <p>
                Jij bent als huishoudelijke hulp de schakel tussen ons en de cliënt. Door jou positieve inzet draag je
                bij aan de optimale dienstverlening. Je werkdag is iedere dag anders.<br>
                De huishoudelijke werkzaamheden verricht je bij onze cliënt thuis die zelfstandig woont.
            </p>
            <p>
                Geinteresseerd in deze vacature? Stuur dan jou CV* en motivatie door op onderstaande knop te drukken.
            </p>
            <p class="mat-small text-accent">*CV graag meesturen in PDF formaat</p>
        `,
    image: '',
    failsafe: ``
  };


  constructor() {
    this.setVerpleging();
    this.setBegeleiding();
    this.setAanvullendeZorg();
    this.setHulpBijHuishouding();
    this.setPersoonlijkeVerzorging();
    this.setZorgaanvraag();
    this.setZorgverleners();
    this.setInformatieEnUitleg();
    this.setVisieEnMissie();
    this.setErkendLeerbedrijf();
    this.setVacatures();
    this.setDisclaimer();
    this.setPrivacyStatement();
    this.setUitslutingsCriteria();
    this.setKlachtenregeling();
  }

  private setBegeleiding(): void {
    this.begeleiding.title = `<h1>Begeleiding</h1>`;
    this.begeleiding.body = `
            <h2>Begeleiding naar zelfredzaamheid</h2>
            <p>‘De regie van uw leven in eigen hand hebben.’ Wij vinden het belangrijk dat u zelf kunt kiezen.</p>
            <p>
                Crystal Care zet zich in om de zelfredzaamheid van de zorgvrager te herstellen, vergroten en in sommige gevallen
                in stand houden van de huidige leefsituatie. Wij vinden het dan ook belangrijk dat de zorgvrager zelf de regie op
                zich neemt en richting geeft aan zijn of haar leven.
            <p>
            <p>Wanneer u tegen problemen aanloopt kunt u onze medewerker(s) vragen samen met u een oplossing te vinden.</p>
            <p>Ondersteunende begeleiding:</p>
            <p>
                <ul>
                    <li>Het bepalen van een dagindeling aan de hand van gesprekken</li>
                    <li>Het onderhouden van sociale vaardigheden en contacten</li>
                    <li>Hulp bij persoonlijke verzorging</li>
                </ul>
            </p>
            <h2>Activerende begeleiding</h2>
            <p>
                Om problemen te voorkomen of te herstellen kunt u bij ons terecht voor begeleiding. Het doel hiervan is
                gedragsverandering. Gedrags- of psychiatrische problemen kunnen zorgen voor mentale druk of het gevoel te geven grip
                te verliezen op het leven. U wordt individueel begeleid bij het omgaan met lichamelijke of psychische problemen, zoals:
            </p>
            <p>
                <ul>
                    <li>Voeren van huishoudelijke en andere dagelijkse taken</li>
                    <li>Persoonlijke verzorging</li>
                    <li>Hulp bij het onderhouden van sociale vaardigheden en contacten</li>
                </ul>
            </p>`;
    this.begeleiding.image = [
      {url: 'assets/images/crystalcare-begeleiding-large.webp', type: 'image/webp'},
      {url: 'assets/images/crystalcare-begeleiding-large.jp2', type: 'image/jp2'},
      {url: 'assets/images/crystalcare-begeleiding-large.jxr', type: 'image/jxr'},

    ];
    this.begeleiding.failsafe = 'assets/images/crystalcare-begeleiding-large.jpg';
    this.begeleiding.description = `
            Crystal Care zet zich in om de zelfredzaamheid van de zorgvrager te herstellen, vergroten en in sommige
            gevallen in stand houden van de huidige leefsituatie. Wij vinden het dan ook belangrijk dat de zorgvrager
            zelf de regie op zich neemt en richting geeft aan zijn of haar leven.`;
  }

  private setAanvullendeZorg(): void {
    this.aanvullendeZorg.title = `<h1>Aanvullende zorg</h1>`;
    this.aanvullendeZorg.body = `
            <h2>Persoonlijke aandacht</h2>
            <p>
                Heb u lichamelijke klachten? Kunt u zelf uw maaltijden niet meer bereiden? U kunt ervoor kiezen een
                zorgverlener u laten te ondersteunen bij uw persoonlijke zorg en begeleiding. Samen met uw naasten (mantelzorgers)
                biedt Crystal Care ondersteuning in het behartigen van de belangen, het bedenken van oplossingen in samenwerking
                en uiteraard de zorg zelf. Ons doel hierin is u een mooie prettige dag te geven.
            </p>
            <h2>Maatwerk</h2>
            <p>
                Zorg op afroep en ongeplande zorg. Deze vorm van zorg kunnen wij particulier leveren maar kan ook uit het
                persoonsgebonden budget (PGB) en via de Zorgverzekeringwet (ZVW).
            </p>
            <h2>Particuliere thuiszorg</h2>
            <p>
                Particuliere thuiszorg is zorg die niet vanuit de ZVW gefinancierd wordt. Een reden om voor particuliere
                thuiszorg te kiezen kan zijn dat u zelf meer behoefte heeft aan zorg, maar deze extra uren niet vergoed krijgt. Of
                dat u als mantelzorger (een deel van) uw werkzaamheden wilt overdragen aan een deskundige zorgverlener. Zo voorkomt
                u overbelasting en houdt u ook nog tijd over voor uzelf en uw familie.
            </p>`;
    this.aanvullendeZorg.image = [
      {url: 'assets/images/crystalcare-aanvullendezorg-large.webp', type: 'image/webp'},
      {url: 'assets/images/crystalcare-aanvullendezorg-large.jp2', type: 'image/jp2'},
      {url: 'assets/images/crystalcare-aanvullendezorg-large.jxr', type: 'image/jxr'}
    ];
    this.aanvullendeZorg.failsafe = 'assets/images/crystalcare-aanvullendezorg-large.jpg';
    this.aanvullendeZorg.description = `Heb u lichamelijke klachten? Kunt u zelf uw maaltijden niet meer bereiden?
        U kunt ervoor kiezen een zorgverlener u laten te ondersteunen bij uw persoonlijke zorg en begeleiding.`;
  }

  private setVerpleging(): void {
    this.verpleging.title = `<h1>Verpleging</h1>`;
    this.verpleging.body = `
            <p>
                Onder wijkverpleging vallen verpleegkundige handelingen die thuis plaatsvinden. Het kan bijvoorbeeld gaan
                om verpleegkundige handelingen zoals wondverzorging, het klaarzetten van medicijnen en deze toedienen, stomazorg
                en katheteriseren. Ook verzorging kan onder de verpleging in de wijk vallen.
            </p>
            <p>
                Verzorging is Assistentie bij de Dagelijkse Levensverrichtingen (ADL) zoals het aan- en uitkleden, het
                wassen en douchen en de verzorging van de huid.
            </p>
            <p>
                Niet alleen verpleegkundige handelingen en ADL (verpleging en verzorging), maar ook het coördineren,
                signaleren, coachen (bijvoorbeeld ondersteuning bij verzorging van uzelf) en preventieve zorg om aandoeningen,
                beperkingen of verslechtering van de gezondheid te voorkomen, behoren tot het takenpakket van de
                verpleegkundige. Als het nodig is maakt Crystal Care afspraken met de gemeente over zorg, welzijn en wonen.
            </p>`;
    this.verpleging.failsafe = 'assets/images/crystalcare-verpleging-large.jpg';
    this.verpleging.image = [
      {url: 'assets/images/crystalcare-verpleging-large.webp', type: 'image/webp'},
      {url: 'assets/images/crystalcare-verpleging-large.jp2', type: 'image/jp2'},
      {url: 'assets/images/crystalcare-verpleging-large.jxr', type: 'image/jxr'}
    ];
    this.verpleging.description = `Onder wijkverpleging vallen verpleegkundige handelingen die thuis plaatsvinden. Het kan bijvoorbeeld gaan
                om verpleegkundige handelingen zoals wondverzorging, het klaarzetten van medicijnen en deze toedienen, stomazorg
                en katheteriseren. Ook verzorging kan onder de verpleging in de wijk vallen.`;
  }

  private setHulpBijHuishouding(): void {
    this.hulpBijHuishouding.failsafe = `assets/images/crystalcare-huishoudelijk.jpg`;
    this.hulpBijHuishouding.image = [
      {url: 'assets/images/crystalcare-huishoudelijk.webp', type: 'image/webp'},
      {url: 'assets/images/crystalcare-huishoudelijk.jp2', type: 'image/jp2'},
      {url: 'assets/images/crystalcare-huishoudelijk.jxr', type: 'image/jxr'}
    ];
    this.hulpBijHuishouding.title = `<h1>Hulp bij huishouding</h1>`;
    this.hulpBijHuishouding.body = `
            <h2>Alledaagse dingen</h2>
            <p>Kosten minder tijd en inspanning</p>
            <p>
                Afhankelijk van de situatie thuis, ondersteunt Crystal Care bij huishoudelijke taken. Het gaat hierbij
                om opruimen, schoonmaken, koken, wassen, strijken en de dagelijkse boodschappen.
            </p>
            <p>
                Wij stellen samen met u een schoonmaakplan op. U kunt zelf aangeven welke schoonmaakwerkzaamheden er
                worden uitgevoerd en wanneer. Wij proberen zoveel mogelijk met vaste medewerkers te werken.
            </p>
            `;
    this.hulpBijHuishouding.description =
      `Afhankelijk van de situatie thuis, ondersteunt Crystal Care bij huishoudelijke taken. Het gaat hierbij
                om opruimen, schoonmaken, koken, wassen, strijken en de dagelijkse boodschappen.`;
  }

  private setPersoonlijkeVerzorging(): void {
    this.persoonlijkeVerzorging.failsafe = 'assets/images/crystalcare-persoonlijk.jpg';
    this.persoonlijkeVerzorging.image = [
      {url: 'assets/images/crystalcare-persoonlijk.webp', type: 'image/webp'},
      {url: 'assets/images/crystalcare-persoonlijk.jp2', type: 'image/jp2'},
      {url: 'assets/images/crystalcare-persoonlijk.jxr', type: 'image/jxr'}
    ];
    this.persoonlijkeVerzorging.title = `<h1>Persoonlijke verzorging</h1>`;
    this.persoonlijkeVerzorging.body = `
            <h2>Observeren</h2>
            <p>elke keer weer</p>
            <p>
                Bij persoonlijke verzorging gaat het om het ondersteunen of overnemen van verzorging bij mensen met een
                aandoening of beperking. Het uitgangspunt van Crystal Care is dat de persoonlijke verzorging gericht is
                op het ondersteunen en begeleiden van de cliënt. Dit kan ook inhouden dat de cliënt wordt gestimuleerd
                om deze activiteiten zelf te doen en aan te leren. Denk bijvoorbeeld aan hulp bij wassen, aankleden, bed
                opmaken, toiletbezoek, eten en drinken.
            </p>`;
    this.persoonlijkeVerzorging.description =
      `Bij persoonlijke verzorging gaat het om het ondersteunen of overnemen van verzorging bij mensen met een
                aandoening of beperking. Het uitgangspunt van Crystal Care is dat de persoonlijke verzorging gericht is
                op het ondersteunen en begeleiden van de cliënt.`;
  }

  private setZorgaanvraag(): void {
    this.zorgAanvraag.title = `<h1>Zorgaanvraag</h1>`;
    this.zorgAanvraag.body = `
            <h2>Intake</h2>
            <p>
                De verpleegkundige brengt samen met specialisten, de cliënt en/of andere betrokkenen de woon- en
                leefsituatie van de cliënt in kaart. De intake is onderdeel van het zorgleefplan. Door aanvullende
                gegevens van bijvoorbeeld de huisartsen, psycholoog, maatschappelijk werker en mantelzorger, kunnen we
                het zorgvraag formuleren.
            </p>
            <h2>Zorgbeschrijving</h2>
            <p>
                Wij beschrijven per functie de activiteiten van onze zorgleveringen; <i class="primary">wat doet de
                zorgverlener per dag, week, of incidenteel?</i> Zo zijn we goed op de hoogte welke specifieke zorg nodig
                is. Ook weten we wat er gebeurt als onze cliënt deze zorg niet zou krijgen. Op deze manier krijgen we
                een duidelijk beeld van wat de cliënt wel of niet kan. De zorgbeschrijving vormt de rode draad in het
                opgestelde zorgleefplan.
            </p>
            <h2>PGB (Persoons Gebonden Budget)</h2>
            <p>
                Onze verpleegkundige stelt een zorgbeschrijving op en geeft advies bij de indiening van het
                PGB-aanvraagformulier. Daarnaast helpt de verpleegkundige bij het opstellen van het budgetplan. Indien u
                gebruik maakt van ‘trekkingsrecht’, kunnen wij u hier in adviseren. U geeft hiermee opdracht aan de
                Sociale Verzekeringsbank (SVB) om betalingen van zorgkosten aan ons te doen. Met een PGB wordt met u een
                zorgovereenkomst gesloten. U en Crystal Care ondertekenen de overeenkomst en wordt doorgestuurd naar het
                SVB
            </p>
        `;
    this.zorgAanvraag.description = ` De verpleegkundige brengt samen met specialisten, de cliënt en/of andere betrokkenen de woon- en
                leefsituatie van de cliënt in kaart. De intake is onderdeel van het zorgleefplan. Door aanvullende
                gegevens van bijvoorbeeld de huisartsen, psycholoog, maatschappelijk werker en mantelzorger, kunnen we
                het zorgvraag formuleren.`;
  }

  private setZorgverleners(): void {
    this.zorgVerleners.title = `<h1>Zorgverleners</h1>`;
    this.zorgVerleners.body = `
            <h2>Helpende zorg en welzijn</h2>
            <p>
                Helpt u op het gebied van gezondheid, wonen en welzijn. Hij/zij maakt met u een werkplanning op basis van
                een zorg- of begeleidingsplan. Daarnaast biedt een Helpende Zorg en Welzijn ondersteuning bij het wonen
                en huishouden.
            </p>
            <h2>Verzorgende IG (Individuele Gezondheidszorg)</h2>
            <p>
                Helpt u bijvoorbeeld bij het injecteren, bij het verzorgen van stoma en het organiseren van medicatie.
                Verzorgende IG’ers hebben theoretische en praktische kennis over het uitvoeren van verpleegtechnische
                handelingen.
            </p>
            <h2>Verpleegkundige (in de wijk)</h2>
            <p>
                Uitvoeren van voorbehouden- en risicovolle handelingen overnemen van bijvoorbeeld wondspoelen,
                katheteriseren, inbrengen en verwisselen canule. Wijkverpleegkundige voeren de regie bij voorbehouden-
                en risicovolle handelingen.
            </p>
        `;
    this.zorgVerleners.description =
      `Helpt u op het gebied van gezondheid, wonen en welzijn. Hij/zij maakt met u een werkplanning op basis van
                een zorg- of begeleidingsplan. Daarnaast biedt een Helpende Zorg en Welzijn ondersteuning bij het wonen
                en huishouden.`;
  }

  private setInformatieEnUitleg(): void {
    this.informatieEnUitleg.title = `<h1>Informatie en uitleg</h1>`;
    this.informatieEnUitleg.body = `
            <h2>Zorgleefplan</h2>
            <p>
                Bij het formuleren van een zorgvraag gebruiken wij de gegevens die we uit de intake hebben verzameld.
                Als het zorgbehoefte juist beschreven is, stelt de verpleegkundige een plan van aanpak op. De
                zorgbeschrijving vormt een belangrijk onderdeel van het zorgleefplan.
            </p>
            <h2>Aanvragen</h2>
            <p>
                Zorg die moet worden aangevraagd wordt door een verpleegkundige opgesteld na overleg met de huisarts,
                maatschappelijk werker en/of specialiste(n).
            </p>
            <h2>ZRM (Zelfredzaamheid-matrix)</h2>
            <p>
                Via een geordend systeem kijken we naar vaardigheden, omstandigheden en beweegredenen in het dagelijks
                leven van de zorgvrager. Dan wordt een waarde voor zelfredzaamheid toegekend.
            </p>
            <h2>WLZ (Wet Langdurige Zorg)</h2>
            <p>
                De Wet langdurige zorg (WlZ) bestaat voor mensen die blijvend 24 uur per dag zorg of toezicht nodig hebben.
            </p>
            <h2>WMO (Wet Maatschappelijke Ondersteuning)</h2>
            <p>
                Na 1 januari 2015 vallen individuele begeleiding, groepsbegeleiding, kortdurend verblijf en persoonlijke
                verzorging onder de WMO. De aanvraag voor WMO loopt via de gemeente.
            </p>
        `;
    this.informatieEnUitleg.image = ``;
    this.informatieEnUitleg.description =
      `Bij het formuleren van een zorgvraag gebruiken wij de gegevens die we uit de intake hebben verzameld.
                Als het zorgbehoefte juist beschreven is, stelt de verpleegkundige een plan van aanpak op. De
                zorgbeschrijving vormt een belangrijk onderdeel van het zorgleefplan.`;
  }

  private setVisieEnMissie(): void {
    this.visieEnMissie.title = `<h1>Visie en missie</h1>`;
    this.visieEnMissie.body = `
            <p>
                ‘Zorgt voor u’ staat bij Crystal Care centraal! Bij kunnen dragen aan deze maatschappelijke opdracht
                inspireert ons. Onze medewerkers zijn warm en betrokken, zij werken vanuit het hart en hebben liefde voor mensen.
            </p>
            <p>
                Het sprankelende van een ‘Crystal’ geeft aan dat er positieve energie zit in onze organisatie. Het klinkt
                vrolijk en energiek. Wij werken zichtbaar met plezier en zijn hierdoor onderscheidend ten opzichte van andere
                zorgaanbieders. Ieder geeft hier een persoonlijke invulling aan.
            </p>
            <p>
                Wij zijn vakbekwaam en continue op zoek zijn naar verbetering. Wij staan open voor vernieuwing, zijn
                nieuwsgierig en hanteren daarbij een luisterende houding naar onze klanten. Wij zoeken samen, en vanuit
                eigen verantwoordelijkheid, naar wat we kunnen bijdragen aan het gewenste resultaat.
            </p>

        `;
    this.visieEnMissie.description = `‘Zorgt voor u’ staat bij Crystal Care centraal! Bij kunnen dragen aan deze
        maatschappelijke opdracht inspireert ons. Onze medewerkers zijn warm en betrokken, zij werken vanuit het hart
        en hebben liefde voor mensen.`;
  }

  private setErkendLeerbedrijf(): void {
    this.erkendLeerbedrijf.title = `<h1>Erkend leerbedrijf</h1>`;
    this.erkendLeerbedrijf.body = `
            <h2>Crystal Care is een Leerbedrijf</h2>
            <p>
                Voor de MBO-studenten in ons land is het leren in de praktijk een onmisbaar deel van hun opleiding.
                Crystal Care beseft dat. Vanaf de start in 2017 heeft Crystal Care zich hard gemaakt om een gecertificeerd
                en erkend leerbedrijf te zijn. En dat certificaat hebben we!<br>
                Bij Crystal Care maak je kennis met de praktijk en leer je wat er van je wordt verwacht.  Een deskundige
                praktijkopleider zorgt voor jouw begeleiding.
            </p>
            <p>
                Als stagiaire krijg je bij Crystal Care de ruimte om te ontwikkelen en te groeien. Elke student krijgt
                begeleiding van een stagebegeleider. Het doel van een stage bij Crystal Care is er om de vaardigheden en
                kennis die je tijdens je studie opdoet in de praktijk te brengen.
            </p>
            <p>
                Ben jij op zoek naar een leerbedrijf in de zorg? Mail je gegevens naar onderstaand e-mailadres:<br>
                Crystal Care<br>
                ${this.address.name ? this.address.name + '<br>' : ''}
                ${this.address.street}, ${this.address.addition}<br>
                ${this.address.zip} te ${this.address.city}<br>
                <a href="mailto:info@crystalcarezorg.nl?Subject=Stage%20plek" target="_top">info@crystalcarezorg.nl</a>
            </p>
            <p>
                <img class="in-text-image" src="/assets/images/sbb-beeldmerk.png" alt="sbb beeldmerk"/>
            </p>
        `;
    this.erkendLeerbedrijf.description =
      `Voor de MBO-studenten in ons land is het leren in de praktijk een onmisbaar deel van hun opleiding.
                Crystal Care beseft dat. Vanaf de start in 2017 heeft Crystal Care zich hard gemaakt om een gecertificeerd
                en erkend leerbedrijf te zijn. En dat certificaat hebben we!`;
  }

  private setVacatures(): void {
    this.vacatures.push(this.vVerzorgendeIG, this.vVerpleegkundige, this.vHelpende, this.vHuishoudelijkeHulp);
  }

  private setDisclaimer(): void {
    this.disclaimer.title = `<h1>Disclaimer</h1>`;
    this.disclaimer.body = `
            <h2>Algemeen</h2>
            <p>
                Hoewel Crystal Care de inhoud van deze website met grote zorg samenstelt, kan het voorkomen dat u
                onvolledige of onjuiste informatie aantreft. Hieraan kunnen geen rechten worden ontleend. Crystal Care
                kan evenmin aansprakelijk worden gesteld voor schade die is opgelopen door de aangeboden informatie.
            </p>

            <h2>Websites van derden</h2>
            <p>
                Op de website van Crystal Care staan links die verwijzen naar websites die niet onder het domein van
                Crystal Care vallen. Op de inhoud van deze pagina's heeft Crystal Care geen invloed. Wij aanvaarden
                daarom geen enkele aansprakelijkheid voor de aldaar aangeboden informatie.
            </p>

            <p>
                Crystal Care behoudt zich het recht voor de website naar eigen inzicht en op ieder door haar gewenst
                moment te veranderen. Crystal Care besteedt de uiterste zorg aan de betrouwbaarheid en actualiteit van
                alle gepubliceerde content. De website wordt dan ook door Crystal Care regelmatig, in ieder geval
                jaarlijks, gecontroleerd op de betrouwbaarheid van informatie.
            </p>
            <p>
                Ondanks de zorg waarmee deze website is samengesteld is het niet uitgesloten dat de informatie op enig
                moment, als gevolg van actuele ontwikkelingen of veranderende inzichten, moet worden herzien. De
                publicatiedatum geeft aan wanneer de informatie voor het laatst is herzien.
            </p>

        `;
  }

  private setPrivacyStatement(): void {
    this.privacyStatement.title = `<h1>Privacyreglement</h1>`;
    this.privacyStatement.body = `
<h2>1. Inleiding</h2>
<p>Binnen Crystal Care worden persoonsgegevens verwerkt. Persoonsgegevens worden verkregen van
de betrokkenen zelf, maar soms ook van derde partijen, bijvoorbeeld de werkgever van de
betrokkene. De betrokkenen moeten erop kunnen vertrouwen dat Crystal Care zorgvuldig met hun
persoonsgegevens omgaat en deze afdoende beveiligt tegen verlies of onrechtmatige verwerking. In
dit privacyreglement laat Crystal Care zien op welke manier zij omgaat met persoonsgegevens en
privacy. Het beschermen van persoonsgegevens is complex en wordt steeds complexer door
technologische ontwikkelingen, nieuwe Europese wetgeving en uitdagingen op het terrein van
veiligheid en beveiliging. Crystal Care heeft privacy hoog in het vaandel staan en vindt het daarom
belangrijk om transparant te zijn over de manier waarop zij ernaar streeft om met persoonsgegevens
om te gaan, en de privacy van betrokkenen te waarborgen.</p>
<p>In dit reglement zet Crystal Care onder meer uiteen welke categorieën van persoonsgegevens zij
verwerkt, voor welke doeleinden en wat de verwerkingsgrondslag is. Zij geeft eveneens aan welke
categorieën van betrokkenen er zijn en welke rechten zij kunnen uitoefenen. Tevens wordt aandacht
geschonken aan de ontvangers van de persoonsgegevens, eventuele (sub)verwerkers), de technische
en organisatorische beveiliging van persoonsgegevens en een procedure datalekken.</p>
<br>
<h2>2. Wet- en regelgeving</h2>
<p>Crystal Care is verantwoordelijk voor het opstellen, uitvoeren en handhaven van een
persoonsgegevensverwerkingsbeleid, dat onder andere in dit reglement uiteen wordt gezet. Het gaat
hier om de Algemene Verordening Gegevensbescherming (AVG) en de Uitvoeringswet Algemene
Verordening Gegevensbescherming.</p>
<h2>3. Begrippen</h2>
<p>In dit reglement wordt een aantal termen gebruikt die een specifieke (wettelijke) betekenis hebben.
Het betreft de volgende termen:</p>
<p><strong><i>Betrokkene:</i></strong> De natuurlijke persoon op wie de persoonsgegevens betrekking hebben. De betrokkene
is degene van wie de gegevens worden verwerkt. Dat kan bijvoorbeeld een medewerker van Crystal
Care, een cliënt of een sollicitant zijn.</p>
<p><strong><i>Verwerker:</i></strong> De persoon of organisatie die persoonsgegevens verwerkt in opdracht van en uitsluitend
ten behoeve van een andere persoon of organisatie, die in dat verband als
verwerkingsverantwoordelijke is aan te merken.</p>
<p><strong><i>Verwerkingsverantwoordelijke:</i></strong> Een persoon of organisatie die alleen of samen met anderen, het
doel van en de middelen voor de verwerking van persoonsgegevens vaststelt. Zo is Crystal Care
bijvoorbeeld een verwerkingsverantwoordelijke als zij de persoonsgegevens van haar cliënten
verwerkt in de cliëntenadministratie. Zij bepaalt immers voor welk doel zij welke persoonsgegevens
van haar cliënten verwerkt.</p>
<p><strong><i>Persoonsgegevens:</i></strong> Alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon
(de betrokkene);</p>
<p><strong><i>Bijzondere persoonsgegevens:</i></strong> Gegevens die betrekking hebben op ras of etnische afkomst, politieke
opvattingen, religieuze of levensbeschouwelijke overtuigingen, het lidmaatschap van een vakbond,
genetische gegevens, biometrische gegevens met het oog op de unieke identificatie van een
persoon, gegevens over gezondheid, seksueel gedrag of seksuele geaardheid.</p>
<p><strong><i>Personeel:</i></strong> Alle personen die werkzaam zijn bij/voor Crystal Care, waaronder begrepen werknemers,
ZZP’ers, stagiaires en vrijwilligers, met uitzondering van uitzendkrachten.</p>
<p><strong><i>Verwerking van persoonsgegevens:</i></strong> Een verwerking omvat alles wat er met een persoonsgegeven
wordt ‘gedaan’, al dan niet via geautomatiseerde procedés. Dat hoeft overigens niet een actieve
handeling te zijn. Voorbeelden van verwerkingen zijn: vastleggen, bewaren, raadplegen, opvragen,
verzamelen, bij elkaar voegen, verstrekken aan een ander, doorgifte en vernietigen.</p>
<p><strong><i>Autoriteit Persoonsgegevens:</i></strong> De autoriteit die tot taak heeft toe te zien op de verwerking van
persoonsgegevens en de naleving van wet- en regelgeving.</p>
<h2>4. Reikwijdte van dit privacyreglement</h2>
<p>Dit reglement is van toepassing op alle verwerkingen van persoonsgegevens van betrokkenen door of
namens Crystal Care.</p>
<p>Dit reglement heeft ten doel dat de verwerking van persoonsgegevens plaats vindt conform de
relevante geldende wet- en regelgeving, waaronder de AVG en de uitvoeringswet AVG. Daarmee
tracht Crystal Care:</p>
<ul><li>de persoonlijke levenssfeer van betrokkene(n) te beschermen tegen onrechtmatige
verwerking en/of misbruik van haar persoonsgegevens, tegen verlies van die gegevens en
tegen het verwerken van onjuiste gegevens;</li>
<li>te voorkomen dat persoonsgegevens verder worden verwerkt of worden verwerkt voor een
ander doel dan het doel waarvoor ze verzameld zijn;</li>
<li>de betrokkene(n) te informeren over de persoonsgegevensverwerkingen die door of namens
de verwerkingsverantwoordelijke plaatsvinden en over de rechten die de betrokkene(n)
heeft/hebben.</li></ul>
<h2>5. Uitgangspunten bij de verwerking van persoonsgegevens</h2>
<p>Crystal Care neemt bij de verwerking van persoonsgegevens de navolgende uitgangspunten in acht
en draagt er zorg voor dat zij ook daadwerkelijk kan aantonen dat deze beginselen in acht worden
genomen en dat zij daarmee voldoet aan haar verantwoordingsplicht uit de AVG.</p>
<p><strong><i>Rechtmatigheid, behoorlijkheid, transparantie</i></strong><br>
Crystal Care streeft ernaar de persoonsgegevens in overeenstemming met de wet- en regelgeving te
verwerken en op een wijze die ten aanzien van de betrokkene(n) rechtmatig, behoorlijk en
transparant is.
</p>
<p><strong><i>Grondslag en doelbinding</i></strong><br>
Crystal Care zorgt ervoor dat persoonsgegevens alleen voor welbepaalde, uitdrukkelijk omschreven
en gerechtvaardigde doelen worden verzameld en verwerkt. Persoonsgegevens worden alleen met
een gerechtvaardigd doel en een gerechtvaardigde grondslag verwerkt.</p>
<p><strong><i>Dataminimalisatie</i></strong><br>
Crystal Care streeft ernaar uitsluitend de persoonsgegevens die minimaal noodzakelijk zijn voor het
vooraf bepaalde doel te verwerken. Zij streeft naar minimale gegevensverwerking. Waar mogelijk
worden minder of geen persoonsgegevens verwerkt.</p>
<p><strong><i>Juistheid</i></strong><br>
Crystal Care streeft ernaar dat de persoonsgegevens die worden verwerkt juist zijn en deze zo nodig
te actualiseren. Zij streeft ernaar om de persoonsgegevens die onjuist zijn, te wissen of te
rectificeren.</p>
<p><strong><i>Bewaren van persoonsgegevens</i></strong><br>
Persoonsgegevens worden niet langer bewaard dan noodzakelijk is, met in achtneming van de
wettelijke bewaartermijnen. Het bewaren van persoonsgegevens kan nodig zijn om de taken van
Crystal Care goed uit te kunnen oefenen, de vooraf bepaalde doelen te realiseren of om wettelijke
verplichtingen te kunnen naleven.<br>
In voorkomend geval zal Crystal Care passende technische en organisatorische maatregelen treffen
om de rechten en vrijheden van de betrokkene(n) te beschermen.</p>
<p><strong><i>Integriteit en vertrouwelijkheid</i></strong><br>
Crystal Care gaat zorgvuldig om met persoonsgegevens en behandelt deze vertrouwelijk. Zo worden
persoonsgegevens alleen verwerkt door personen die daartoe geautoriseerd zijn en slechts voor het
doel waarvoor deze zijn verzameld. Daarbij zorgt Crystal Care voor passende organisatorische en
technische maatregelen die waarborgen dat persoonsgegevens passend worden beveiligd en dat zij
beschermd zijn tegen ongeoorloofde en onrechtmatige verwerking, tegen onopzettelijk verlies,
vernietiging of beschadiging.</p>
<p><strong><i>Verwerkers</i></strong><br>
In het geval van samenwerking met externe partijen - verwerkers-, waarbij sprake is van verwerking
van persoonsgegevens, maakt Crystal Care in een zogenaamde verwerkersovereenkomst afspraken
over de eisen waar deze gegevensuitwisseling aan moet voldoen. Deze afspraken dienen te voldoen
aan de wet- en regelgeving. Crystal Care controleert deze afspraken periodiek.</p>
<p><strong><i>Privacy by Design en Privacy by Default</i></strong><br>
Crystal Care draagt er zorg voor dat bij de ontwikkeling van (nieuwe) producten, diensten en
(informatie)systemen rekening wordt gehouden met de beginselen van de AVG en overige
toepasselijke privacy wet- en regelgeving. Rekening houdend met de stand van de techniek, de
kosten en de aard, de omvang, de context en het doel van de verwerking, alsook met de risico’s voor
de rechten en vrijheden van de betrokkenen die met de specifieke verwerking zijn verbonden, treft
Crystal Care in voorkomende gevallen privacybescherming verhogende maatregelen.</p>
<p><strong><i>Rechten van betrokkenen</i></strong><br>
Crystal Care streeft ernaar alle rechten van betrokkenen te respecteren en zoveel als redelijkerwijs
mogelijk eventuele verzoeken tot uitoefening van die rechten door betrokkenen te honoreren. Onder
rechten van betrokken(n) worden verstaan: het recht op informatie, op inzage, op rectificatie, op
vergetelheid, op beperking van de verwerking, op dataportabiliteit en recht van bezwaar.</p>
<h2>6. Functionaris Gegevensbescherming</h2>
<p>Crystal Care heeft vrijwillig gekozen voor het aanstellen van een Functionaris Gegevensbescherming,<br>
dit is</p>
<p>Hennie Oexman<br>
075-6151119<br>
info@crystalcarezorg.nl<br>
</p>
<h2>7. Persoonsgegevens verwerkingen binnen Crystal Care</h2>
<p><strong>Doeleinden</strong><br>
Op grond van de geldende wet- en regelgeving mogen persoonsgegevens alleen verzameld worden
als daarvoor vooraf een doel is vastgesteld. Het doel moet uitdrukkelijk omschreven en
gerechtvaardigd zijn. De persoonsgegevens mogen niet voor andere doelen worden verwerkt.</p>
<p><strong>Rechtmatige grondslag verwerking van persoonsgegevens</strong>
Op grond van de geldende wet- en regelgeving geldt daarnaast dat voor elke verwerking van
persoonsgegevens een rechtmatige grondslag (zoals die worden genoemd in artikel 6 AVG) moet
bestaan – derhalve naast de eis van een gerechtvaardigd en uitdrukkelijk omschreven doel. Dat
betekent dat Crystal Care-persoonsgegevens alleen mag verwerken in een van de volgende
limitatieve gevallen:</p>
<ul><li>wanneer de betrokkene(n) toestemming heeft/hebben gegeven voor de specifieke verwerking
(inclusief het doel daarvan);</li>
<li>als de verwerking noodzakelijk is voor de uitvoering van een overeenkomst waar de
betrokkene partij bij is, of voor handelingen die op verzoek van de betrokkene(n) worden
verricht en die noodzakelijk zijn voor het sluiten van een overeenkomst;</li>
<li>de verwerking noodzakelijk is om te voldoen aan een wettelijke verplichting;</li>
<li>de verwerking noodzakelijk is om de vitale belangen van de betrokkene(n) of een andere
natuurlijke persoon te beschermen. Hiervan is niet snel sprake, wel – bijvoorbeeld - indien
gegevens moeten worden verwerkt om een acute en ernstige bedreiging voor de gezondheid
van de betrokkene(n) te bestrijden;</li>
<li>de verwerking noodzakelijk is voor de behartiging van gerechtvaardigd belang van Crystal Care
of een derde, tenzij het belang of fundamentele rechten en vrijheden van de betrokkene(n)
prevaleren, met name wanneer de betrokkene een kind is.</li>
</ul>
<p>In de meeste gevallen zal Crystal Care een andere grondslag hebben dan de grondslag toestemming.
In die gevallen waarin toestemming van de betrokkene een noodzakelijke voorwaarde is voor de
persoonsgegevensverwerking is relevant wat er onder toestemming wordt verstaan. Onder
toestemming wordt verstaan: elke vrije, specifieke, geïnformeerde en ondubbelzinnige wilsuiting
waarmee de betrokkene door middel van een verklaring of een ondubbelzinnige actieve handeling
de verwerking van zijn/haar persoonsgegevens aanvaardt. Indien de betrokkene toestemming geeft
voor de verwerking van zijn/haar persoonsgegevens zal Crystal Care dat documenteren. Daarmee
kan zij aantonen dat de toestemming is gegeven. De betrokkene heeft het recht zijn/haar
toestemming te allen tijde in te trekken. Het intrekken van de toestemming laat de rechtmatigheid
van de verwerking op basis van de toestemming voor de intrekking echter onverlet. Crystal Care
draagt er zorg voor dat de betrokkene zijn/haar toestemming net zo eenvoudig kan intrekken, als dat
hij/zij zijn/haar toestemming heeft gegeven aan Crystal Care.</p>
<p><strong>Rechtmatige grondslag verwerking van bijzondere persoonsgegevens</strong>
Bijzondere persoonsgegevens worden niet verwerkt tenzij met inachtneming van het bepaalde in
artikel 9 van de AVG.</p>
<p><strong>Persoonsgegevensverwerking binnen Crystal Care</strong>
Binnen Crystal Care vinden onder andere de navolgende persoonsgegevensverwerkingen plaats voor
de navolgende doelen:</p>
<br>
<p><strong>PERSONEEL</strong></p>
<h2>7.1 Personeel</h2>
<h2>7.1.1 De verwerking van persoonsgegevens van personeel van Crystal Care heeft ten doel:</h2>
<ol type="a">
<li> het aangaan van de overeenkomst tussen betrokkene en Crystal Care, waaronder de
arbeidsovereenkomst, de overeenkomst van opdracht, de stage- overeenkomst of de
vrijwilligersovereenkomst;</li>
<li>het vaststellen van (salaris)aanspraken en (arbeids)voorwaarden;</li>
<li>het (laten) uitbetalen van salaris, en zo nodig de afdracht van belastingen en premies;</li>
<li>de uitvoering van een voor de betrokkene geldende (arbeids)voorwaarde;</li>
<li>het innen van vorderingen, waaronder begrepen het in handen van derden stellen van die
vorderingen;</li>
<li>het beëindigen van de onder a genoemde overeenkomsten;</li>
<li>het geven van leiding aan de werkzaamheden van betrokkene (artikel 6 lid 1 sub b AVG);</li>
<li>het verstrekken van de bedrijf medische zorg voor betrokkene en het kunnen nakomen van
re-integratieverplichtingen bij verzuim (artikel 6 lid 1 sub c AVG);</li>
<li>het toegang verlenen tot het bedrijfsnetwerk (artikel 6 lid 1 sub b AVG);</li>
<li>het regelen van en de controle van aanspraken op uitkeringen in verband met de beëindiging
van een dienstverband (artikel 6 lid 1 sub b AVG);</li>
<li>de behandeling van personeelszaken, anders dan genoemd onder a. tot en met j., waaronder
tevens wordt begrepen informatie en communicatie;</li>
</ol>
<h2>7.1.2. Grondslagen van de gegevensverwerking:</h2>
<p>De verwerking van de persoonsgegevens ten behoeve van de doelen genoemd in artikel 7.1.1. vindt
plaats op basis van de grondslagen genoemd in artikel 6 AVG.</p>
<h2>7.1.3. Geen andere persoonsgegevens worden verwerkt dan:</h2>
<ol><li>naam, voornamen, voorletters, titulatuur, geslacht, geboortedatum, adres, postcode,
woonplaats, telefoonnummer en soortgelijke voor communicatie benodigde gegevens, zoals
het e-mailadres (zowel zakelijk als privé) alsmede IBAN- nummer van de betrokkene;</li>
<li>BSN-nummer;</li>
<li>kopie ID-bewijs/paspoort;</li>
<li>een personeelsnummer dat geen andere informatie bevat dan bedoeld onder a;</li>
<li>nationaliteit en geboorteplaats;</li>
<li>gegevens als bedoeld onder a., van de ouders, voogden of verzorgers van minderjarige
werknemers;</li>
<li>gegevens betreffende gevolgde en te volgen opleidingen, cursussen en stages;</li>
<li>gegevens betreffende de arbeidsvoorwaarden;</li>
<li>gegevens betreffende het berekenen, vastleggen en betalen van salarissen, vergoedingen en
andere geldsommen en beloningen in natura;</li>
<li>gegevens betreffende het berekenen, vastleggen en betalen van belasting en premies;</li>
<li>gegevens betreffende de functie of de voormalige functie(s), alsmede betreffende de aard, de
inhoud en de beëindiging van voorgaande dienstverbanden;</li>
<li>gegevens met het oog op de administratie van de aanwezigheid van de betrokkenen op de
plaats waar de arbeid wordt verricht en hun afwezigheid in verband met verlof,
arbeidsduurverkorting, bevalling of ziekte, met uitzondering van gegevens over de aard van
de ziekte;</li>
<li>gegevens die in het belang van de betrokkenen worden opgenomen met het oog op hun
arbeidsomstandigheden en veiligheid;</li>
<li>gegevens, waaronder begrepen gegevens betreffende gezinsleden en voormalige gezinsleden
van de betrokkenen, die noodzakelijk zijn met het oog op overeengekomen
arbeidsvoorwaarden;</li>
<li>gegevens met betrekking tot de functie-uitoefening, de personeelsbeoordeling en de
loopbaanbegeleiding, voor zover die gegevens bij de betrokkenen bekend zijn;</li>
<li>inloggegevens van het bedrijfsnetwerk;</li>
<li>foto's en videobeelden met of zonder geluid van activiteiten van Crystal Care;</li>
<li>camerabeelden van het bedrijfsterrein en de algemeen toegankelijke ruimten van Crystal
Care;</li>
<li>de gegevens met betrekking tot het tijdstip, de datum en de plaats waarop de cameraopnamen zijn gemaakt;</li>
<li>IP-adressen;</li>
<li>Huwelijkse staat;</li>
<li>Kentekens van motorvoertuigen;</li>
<li>BIG-Registratie;</li>
<li>Verklaring Omtrent Gedrag (VOG);</li>
<li>andere dan de onder 1. tot en met 24. bedoelde gegevens waarvan de verwerking wordt
vereist ingevolge of noodzakelijk is met het oog op de toepassing van een andere niet nader
genoemde wet.</li>
</ol>
<h2>7.1.4. Voor de onder 7.1.1. genoemde doelen worden de navolgende persoonsgegevens genoemd in
artikel 7.1.3. verwerkt:</h2>
<ol type="a">
<li>het aangaan van de arbeidsovereenkomst;</li>
<li>het vaststellen van salarisaanspraken en arbeidsvoorwaarden;</li>
<li>het (laten) uitbetalen van salaris en de afdracht van belastingen en premies;</li>
<li>de uitvoering van een voor de betrokkene geldende arbeidsvoorwaarden;</li>
<li>het innen van vorderingen, waaronder begrepen het in handen van derden stellen van die
vorderingen;</li>
<li>het verlenen van ontslag;</li>
<li>het geven van leiding aan de werkzaamheden van betrokkene;</li>
<li>het verstrekken van de bedrijf medische zorg voor betrokkene en het kunnen nakomen van
re-integratieverplichtingen bij verzuim;</li>
<li>het toegang verlenen tot het bedrijfsnetwerk;</li>
<li>het regelen en de controle van aanspraken op uitkeringen in verband met de beëindiging
van een dienstverband;</li>
<li>de behandeling van personeelszaken, anders dan genoemd onder a. tot en met j.</li>
<li>het laten uitoefenen van accountantscontrole en het laten vaststellen van aanspraken op
bekostiging;</li>
</ol>
<h2>7.1.5. Bewaarplaats van de persoonsgegevens</h2>
<p>De persoonsgegevens genoemd in artikel 7.1.3 worden bewaard in een fysiek dossier (gegevens van
personeel uit dienst voor medio 2015), dat wordt bewaard in het kantoor van de heer AAA. En
worden (tevens) bewaard in het digitale administratiesysteem.</p><br>
<p><strong>UITZENDKRACHTEN</strong></p>
<h2>7.2 Uitzendkrachten</h2>
<h2>7.2.1. De verwerking van persoonsgegevens van uitzendkrachten van Crystal Care heeft ten doel:</h2>
<ol type="a">
<li>het vaststellen van aanspraken van het uitzendbureau;</li>
<li>de uitvoering van de uitzendovereenkomst;</li>
<li>het geven van leiding aan de werkzaamheden van betrokkene;</li>
<li>het toegang verlenen tot het bedrijfsnetwerk;</li>
<li>het laten uitoefenen van accountantscontrole en het laten vaststellen van aanspraken op
 bekostiging.</li>
</ol>

<h2>7.2.2. Grondslagen van de gegevensverwerking:</h2>
<p>De verwerking van de persoonsgegevens ten behoeve van de doelen genoemd in artikel 7.2.1. vindt
plaats op basis van de grondslagen genoemd in artikel 6 AVG.</p>
<h2>7.2.3. Geen andere persoonsgegevens worden verwerkt dan:</h2>
<p>naam, voornamen, geslacht en voor communicatie benodigde gegevens, zoals telefoonnummer en emailadres</p>
<p><strong>SOLLICITANTEN</strong></p>
<h2>7.3 Sollicitanten</h2>
<h2>7.3.1. De verwerking van persoonsgegevens van sollicitanten van Crystal Care heeft ten doel:</h2>
<ol type="a"><li>de beoordeling van de geschiktheid van betrokkene voor een functie die vacant is en
communicatie daarover met sollicitant;</li>
<li>de beoordeling van de geschiktheid van betrokkene voor een functie die in de nabije
toekomst vacant kan komen;</li>
<li>de afhandeling van de door de sollicitant gemaakte onkosten;</li>
<li>de uitvoering of toepassing van enige toepasselijk wet- en regelgeving.</li></ol>
<h2>7.3.2. Grondslagen van de gegevensverwerking:</h2>
<p>De verwerking van de persoonsgegevens ten behoeve van de doelen genoemd in artikel 7.1.1. vindt
plaats op basis van de grondslagen genoemd in artikel 6 AVG.</p>
<h2>7.3.3. Geen andere persoonsgegevens worden verwerkt dan:</h2>
<ol type="a"><li>naam, voornamen, voorletters, titulatuur, geslacht, geboortedatum, adres, postcode,
woonplaats, telefoonnummer en soortgelijke voor communicatie benodigde gegevens, zoals
het e-mailadres alsmede IBAN-nummer van de betrokkene;</li>
<li>stages;</li>
<li>gegevens betreffende de aard en inhoud van de huidige dienstbetrekking, alsmede
betreffende de beëindiging ervan;</li>
<li>gegevens betreffende de aard en inhoud van de vorige dienstbetrekkingen, alsmede
betreffende de beëindiging ervan;</li>
<li>andere gegevens met het oog op het vervullen van de functie (bijvoorbeeld gegevens in het
kader van een te voeren voorkeursbeleid voor minderheden of re-integratiebeleid);</li>
<li>andere gegevens met het oog op het vervullen van de functie, die door of na toestemming
van de betrokkene zijn verstrekt (assessments, psychologisch onderzoek, uitslag medische
keuring);</li>
<li>andere dan de onder a. tot en met f. bedoelde gegevens waarvan de verwerking wordt
vereist ingevolge of noodzakelijk is met het oog op de toepassing van een andere wet.</li></ol>
<h2>7.3.4. Voor de onder 7.3.1. genoemde doelen worden de navolgende persoonsgegevens genoemd in
artikel 7.3.3. verwerkt:</h2>
<ol type="1"><li>de beoordeling van de geschiktheid van betrokkene voor een functie die vacant is;</li>
<li>de beoordeling van de geschiktheid van betrokkene voor een functie die in de nabije
toekomst vacant kan komen;</li>
<li>de uitvoering of toepassing van wetgeving.</li></ol>
<h2>7.3.5. Bewaarplaats van de persoonsgegevens</h2>
<p>De persoonsgegevens onder a. tot en met g. worden bewaard op servers van Crystal Care.</p>
<p><strong>BEZOEKERS</strong></p>
<h2>7.4. Bezoekers Website</h2>
<p>Crystal Care informeert bezoekers van de website van Crystal Care.nl bij een bezoek aan de website
over de doeleinden en (persoonsgegevens)gegevens (cookies daaronder begrepen) die worden
verwerkt bij een bezoek aan de website.</p>
<p><strong>CLIENTEN</strong></p>
<h2>7.5 Cliënten</h2>
<h2>7.5.1. De verwerking van persoonsgegevens van cliënten heeft ten doel:</h2>
<ol type="a"><li>het aangaan en de uitvoering van de zorgovereenkomst;</li>
<li>het onderhouden van contacten door de verwerkingsverantwoordelijke met de familieleden
en mantelzorgers van cliënten in het kader van het aangaan en de uitvoering van de
zorgovereenkomst;</li>
<li>het berekenen en vastleggen van inkomsten en uitgaven en het doen van betalingen;</li>
<li>de uitvoering of de toepassing van enige wet- en regelgeving;</li>
<li>het innen van vorderingen, waaronder begrepen het in handen van derden stellen van die
vorderingen;</li>
<li>het behandelen van geschillen;</li>
<li>het laten uitoefenen van accountantscontrole en het laten vaststellen van aanspraken op
bekostiging;</li></ol>
<h2>7.6.2. Grondslagen van de gegevensverwerking:</h2>
<p>De verwerking van de persoonsgegevens ten behoeve van de doelen genoemd in artikel 7.6.1. vindt
plaats op basis van de grondslagen genoemd in artikel 6 AVG.</p>
<h2>7.6.3. Geen andere persoonsgegevens worden verwerkt dan:</h2>
<ol type="1"><li>naam, voornamen, voorletters, titulatuur, geslacht, geboortedatum, geboorteplaats, adres,
postcode, woonplaats, telefoonnummer en soortgelijke voor communicatie benodigde
gegevens, zoals het e-mailadres alsmede IBAN-nummer van cliënten;</li>
<li>BSN-nummer;</li>
<li>Huwelijkse staat;</li>
<li>gegevens als bedoeld onder 1., van de ouders, voogden of verzorgers van minderjarige
cliënten;</li>
<li>gegevens als bedoeld onder 1., waaronder begrepen gegevens betreffende gezinsleden en
voormalige gezinsleden van cliënten, van familieleden en/of mantelverzorgers van cliënten,
die nodig zijn met het oog op de uitvoering van de zorgovereenkomst;</li>
<li>gegevens betreffende de indicatie van cliënten (van het Centrum Indicatiestelling Zorg (CIZ));</li>
<li>gegevens betreffende de gezondheid van cliënten, ofwel de medische gegevens die nodig zijn
voor het uitvoeren van de zorgovereenkomst;</li>
<li>gegevens betreffende het berekenen, vastleggen en betalen van belasting en premies,
gegevens van de zorgverzekeraar;</li>
<li>gegevens die in het belang van cliënten worden opgenomen met het oog op hun gezondheid
en veiligheid;</li>
<li>andere dan de onder 1. tot en met 9. bedoelde gegevens waarvan de verwerking wordt
vereist ingevolge hetgeen noodzakelijk is met het oog op de toepassing van een andere niet
nader genoemde wet.</li></ol>
<h2>7.6.4. Voor de onder 7.6.1. genoemde doelen worden de navolgende persoonsgegevens genoemd in
artikel 7.6.3. verwerkt:</h2>
<ol type="1"><li>het aangaan en de uitvoering van de zorgovereenkomst;</li>
<li>het onderhouden van contacten door de verwerkingsverantwoordelijke met de familieleden
en mantelzorgers van cliënten in het kader van het aangaan en de uitvoering van de
zorgovereenkomst;</li>
<li>het berekenen en vastleggen van inkomsten en uitgaven en het doen van betalingen;</li>
<li>de uitvoering of de toepassing van enige wet- en regelgeving;</li>
<li>het innen van vorderingen, waaronder begrepen het in handen van derden stellen van die
vorderingen;</li>
<li>het behandelen van geschillen;</li>
<li>het laten uitoefenen van (accountants)controle en het laten vaststellen van aanspraken op
bekostiging;</li></ol>
<h2>7.6.5. Bewaarplaats van de persoonsgegevens</h2>
<ol type="1"><li>De persoonsgegevens genoemd in artikel 7.6.3 onder a. tot en met k. worden bewaard in een
fysiek dossier (gegevens van voor medio 2016), dat wordt bewaard in het kantoor van AAA.</li>
<li>De persoonsgegevens genoemd in artikel 7.6.3. worden (tevens) bewaard in het digitale
administratiesysteem (vanaf medio 2016)</li>
<li>De persoonsgegevens genoemd in artikel 7.6.3. worden bewaard op servers die zich in
afgesloten en slechts voor bevoegden toegankelijke datacentra binnen de Europese Unie
bevinden.</li>
<li>In het kader van diensten die door derden worden verricht wordt tevens gebruik gemaakt
van de servers van door Crystal Care gecontracteerde verwerkers alsmede van servers van
derden.</li></ol>
<h2>8. Toegang tot persoonsgegevens en verwerkers</h2>
<p>Toegang tot persoonsgegevens hebben degenen, medewerkers en derden, die zijn belast met of
leidinggeven aan de activiteiten die in verband staan met de verwerking van de desbetreffende
persoonsgegevens of die daarbij noodzakelijk zijn betrokken.</p>
<p>Indien derde partijen een (deel van de) verwerking namens Crystal Care verrichten (zogenaamde
verwerkers), zal Crystal Care met die partijen een verwerkersovereenkomst sluiten. Bovendien
schakelt Crystal Care alleen verwerkers in die afdoende garanties bieden en afdoende maatregelen
treffen zodat de bescherming van de persoonsgegevens gewaarborgd zal zijn en de verwerking
daarvan ook bij hen in overeenstemming is met de geldende wet- en regelgeving. Crystal Care houdt
een overzicht van de door haar ingeschakelde verwerkers bij.</p>
<p>Andere (rechts)personen krijgen alleen toegang tot de persoonsgegevens die Crystal Care verwerkt
indien:</p>
<ul><li>de betrokkene (of zijn/haar ouders/vertegenwoordigers in het geval van een minderjarig
kind) zijn uitdrukkelijke toestemming heeft verleend voor het verstrekken (van toegang tot) de
persoonsgegevens; of</li>
<li>het verstrekken van (toegang tot) de persoonsgegevens noodzakelijk is voor de nakoming van
een wettelijke plicht door Crystal Care; of</li>
<li>het verstrekken van (toegang tot) de persoonsgegevens noodzakelijk is vanwege een vitaal
belang van de betrokkene (bijvoorbeeld een dringende medische noodzaak).</li></ul>
<h2>9. Rechten betrokkenen</h2>
<p>Uit de toepasselijke wet- en regelgeving volgt dat betrokkenen een aantal rechten hebben die zij
jegens Crystal Care kunnen doen gelden. Het betreft de volgende rechten:</p>
<ul>
<li>Recht op informatie;</li>
<li>Recht op inzage;</li>
<li>Recht op rectificatie;</li>
<li>Recht op vergetelheid;</li>
<li>Recht op beperking van de verwerking;</li>
<li>Recht op dataportabiliteit;</li>
<li>Recht van bezwaar.</li></ul>
<p>Crystal Care zal de te verstrekken informatie en de met de betrokken te voeren communicatie in
beknopte, transparante, begrijpelijke en gemakkelijke toegankelijk vorm en in duidelijke en
eenvoudige taal verstrekken/voeren. De informatie wordt in beginsel schriftelijk verstrekt.
Crystal Care daaronder draagt er zorg voor zo spoedig mogelijk te reageren op rechtmatige
verzoeken van betrokkenen, doch uiterlijk binnen een maand na ontvangst van het verzoek.
Afhankelijk van de complexiteit van de verzoeken en van het aantal verzoeken kan die termijn indien
nodig met nog eens twee maanden worden verlengd. Crystal Care stelt de betrokkene binnen één
maand na ontvangst van het verzoek in kennis van een dergelijke verlenging. De informatie wordt in
beginsel elektronisch verstrekt.</p>
<p>Indien Crystal Care twijfelt aan de identiteit van de verzoeker, vraagt zij zo spoedig mogelijk aan de
verzoeker schriftelijk nadere gegevens inzake zijn/haar identiteit te verstrekken of een geldig
identiteitsbewijs te overleggen. Hierdoor wordt de beantwoordingstermijn als bedoeld in lid 3 van dit
artikel opgeschort tot het tijdstip dat het gevraagde bewijs is geleverd.</p>
<p>Wanneer Crystal Care geen gevolg geeft aan het verzoek van de betrokkene, deelt zij deze laatste
onverwijld en uiterlijk binnen één maand na ontvangst van het verzoek mee waarom het verzoek
zonder gevolg is gebleven en informeert zij hem/haar over de mogelijkheid om een klacht in te
dienen bij de Autoriteit Persoonsgegevens en beroep bij de rechter in te stellen.</p>
<p>Behandeling van verzoeken en het verstrekken van gevraagde informatie is in beginsel kosteloos,
tenzij verzoeken kennelijk ongegrond of buitensporig zijn. In voorkomend geval mag Crystal Care een
vergoeding vragen voor de administratieve kosten die gepaard gaan met het verstrekken van de
informatie of communicatie of met het treffen van de gevraagde maatregelen, dan wel weigeren om
gevolg te geven aan het verzoek.</p>
<p>Indien Crystal Care voldoet aan een verzoek tot rectificatie of verwijdering van persoonsgegevens of
beperking van de verwerking, stelt zij iedere ontvanger aan wie de persoonsgegevens zijn verstrekt
daarvan in kennis, tenzij dit onmogelijk blijkt of onevenredig veel inspanning vergt. Indien de
betrokkene hierom verzoekt verstrekt Crystal Care informatie over deze ontvangers.
De uitoefening van het inzagerecht en het recht op dataportabiliteit wordt begrensd door de rechten
en vrijheden van anderen. De uitoefening daarvan zal derhalve geen afbreuk doen aan de rechten en
vrijheden van derden.</p>
<p>Betrokkenen kunnen hun rechten op de volgende wijze uitoefenen:</p>
<p><strong>Recht op informatie:</strong> De betrokkene heeft recht op transparante, begrijpelijke en gemakkelijk
toegankelijke informatie ter zake de verwerking van zijn/haar persoonsgegevens door Crystal Care.
Deze informatie kan de betrokkene verkrijgen bij de functionaris gegevensbescherming. Crystal Care
geeft tevens invulling aan haar plicht tot het verstrekken van informatie door middel van dit
reglement.</p>
<p><strong>Recht op inzage:</strong> Betrokkene heeft de mogelijkheid om aan Crystal Care te vragen óf, en op welke
manier, zijn/haar persoonsgegevens worden verwerkt. Hij/Zij kan van dit recht gebruik maken door
de Functionaris Gegevensbescherming schriftelijk te verzoeken opgave te verstrekken van de
persoonsgegevens die Crystal Care van hem/haar verwerkt. Crystal Care verstrekt tevens informatie
over de verwerkingsdoeleinden, de categorieën van persoonsgegevens, de ontvangers van de
persoonsgegevens, indien mogelijk de bewaartermijn (en als dat niet mogelijk is: de criteria aan de
hand waarvan wordt bepaald hoe lang de persoonsgegevens worden bewaard), de overige rechten
van de betrokkenen, het recht van de betrokkene om een klacht in te dienen bij de Autoriteit
Persoonsgegevens. Tot slot verstrekt Crystal Care informatie over de bron van de persoonsgegevens
als de persoonsgegevens niet bij de betrokkene zelf zijn verzameld.</p>
<p><strong>Recht op rectificatie:</strong> De betrokkene heeft het recht om Crystal Care te verzoeken zijn/ haar
persoonsgegevens te rectificeren of aan te vullen indien deze onjuist of onvolledig zijn.</p>
<p><strong>Recht op vergetelheid:</strong> De betrokkene heeft het recht om Crystal Care te verzoeken zijn/ haar
persoonsgegevens te verwijderen. De betrokkene kan hiertoe een verzoek doen bij de Functionaris
Gegevensbescherming. Crystal Care zal dan nagaan of en in hoeverre aan het verzoek van
betrokkene kan worden voldaan. Crystal Care is gehouden aan dat verzoek te voldoen indien de
persoonsgegevens niet langer nodig zijn voor doel waarvoor ze zijn verzameld, de betrokkene de
toestemming waarop de verwerking berust intrekt en er geen andere rechtsgrond voor de
verwerking bestaat, de betrokkene bezwaar maakt tegen de verwerking en er geen prevalerende
dwingende gerechtvaardigde gronden voor de verwerking zijn, de persoonsgegevens onrechtmatig
zijn verwerkt of de persoonsgegevens gewist moeten worden om te kunnen voldoen aan een
wettelijke verplichting die op Crystal Care rust.</p>
<p>Crystal Care is niet gehouden om aan het verzoek tot verwijdering van de persoonsgegevens te
voldoen indien de verwerking daarvan noodzakelijk is voor de uitoefening van het recht op vrijheid
van meningsuiting en informatie, voor nakomen van een op Crystal Care rustende wettelijke
verplichting, om redenen van algemeen belang op het gebied van volksgezondheid (een en ander in
lijn met het bepaalde in de AVG), met het oog op archivering in het algemeen belang,
wetenschappelijk of historisch onderzoek of statistische doeleinden overeenkomstig artikel 89 van de
AVG of voor de instelling, uitoefening of onderbouwing van een rechtsvordering.</p>
<p><strong>Recht op beperking van de verwerking:</strong> De betrokkene heeft het recht om Crystal Care te verzoeken
de verwerking van zijn/haar persoonsgegevens te beperken indien een van de volgende situaties aan
de orde is:</p>
<ul><li>de juistheid van de persoonsgegevens wordt betwist door de betrokkenen, gedurende een periode
die Crystal Care in staat stelt de juistheid van de persoonsgegevens te controleren;</li>
<li>de verwerking is onrechtmatig en de betrokkene wenst niet dat de persoonsgegevens worden
gewist maar slechts het gebruik daarvan wordt beperkt;</li>
<li>Crystal Care heeft de persoonsgegevens niet meer nodig voor de doeleinden waarvoor zij ze heeft
verkregen, maar de betrokkene heeft ze nodig voor de instelling, uitoefening of onderbouwing van
de rechtsvordering;</li>
<li>nadat de betrokkene bezwaar heeft gemaakt; in afwachting van het antwoord op de vraag of de
belangen van Crystal Care zwaarder wegen dan die van de betrokkene.</li></ul>
<p>Indien een verzoek tot beperking wordt gehonoreerd worden de persoonsgegevens - met
uitzondering van de opslag daarvan - uitsluitend verwerkt met toestemming van de betrokkene.
Crystal Care zal in dat geval in haar bestanden/systemen aangeven welke persoonsgegevens op
grond van een gehonoreerd verzoek zijn beperkt.</p>
<p>Indien een gehonoreerd verzoek om beperking van de verwerking wordt opgeheven, wordt de
betrokkene op de hoogte gebracht voordat de beperking van de verwerking door Crystal Care wordt
opgeheven.</p>
<p><strong>Recht op dataportabiliteit:</strong>De betrokkene heeft het recht zijn/haar persoonsgegevens, die hij/zij zelf
aan Crystal Care heeft verstrekt, in een gestructureerde, gangbare en machine leesbare vorm van
Crystal Care te verkrijgen of Crystal Care te verzoeken deze over te dragen aan een andere
verwerkingsverantwoordelijke. Dit geldt echter uitsluitend indien de verwerking berust op
toestemming of op noodzakelijkheid voor de uitvoering van de overeenkomst waarbij de betrokkene
partij is én de verwerking via geautomatiseerde procedés wordt verricht.</p>
<p><strong>Recht op bezwaar:</strong> Betrokkene heeft het recht om bezwaar te maken tegen de verwerking van
zijn/haar persoonsgegevens, indien verwerking zijn grondslag vindt in de gerechtvaardigde belangen
van Crystal Care. Zij zal hieraan voldoen, tenzij er gerechtvaardigde gronden zijn voor de verwerking
die zwaarder wegen dan de belangen, rechten en vrijheden van de betrokkene of indien ze verband
houden met de instelling, uitoefening of onderbouwing van een rechtsvordering. Indien de
persoonsgegevens ten behoeve van de direct marketing worden verwerkt, zal Crystal Care altijd aan
het verzoek voldoen.</p>
<h2>10. Getroffen technische en organisatorische (beveiligings-)maatregelen</h2>
<p>De verwerkingsverantwoordelijke draagt zorg voor passende technische en organisatorische
maatregelen ter voorkoming van verlies of onrechtmatige verwerking van persoonsgegevens.
De wijze van beveiliging van beveiliging van de persoonsgegevens is beschreven en toegelicht in het
informatiebeveiligingsbeleid van Crystal Care.</p>
<p>Tevens hanteert Crystal Care een gedragscode, onder andere voor het gebruik van ICT, e-mail,
internet, en sociale media. Deze gedragscode geeft de wijze aan waarop binnen Crystal Care wordt
omgegaan met informatie- en communicatietechnologie. Dit protocol omvat (gedrags)regels ten
aanzien het gebruik van de ICT en geeft regels voor welke doeleinden en op welke wijze controle
plaats vindt op dit gebruik.</p>
<p>De door Crystal Care getroffen technische en organisatorische maatregelen garanderen, rekening
houdend met de stand der techniek en de uitvoeringskosten, alsook met de aard, de omvang, de
context en de verwerkingsdoeleinden en de qua waarschijnlijkheid en ernst uiteenlopende risico’s
voor de rechten en vrijheden van de betrokkenen, een op het risico afgestemd passend
beveiligingsniveau. De maatregelen zijn er mede op gericht onnodige verzameling en verdere
verwerking van persoonsgegevens te voorkomen.</p>
<h2>11. Beveiligingsincidenten en datalekken</h2>
<p>Ondanks de beveiligingsmaatregelen die Crystal Care heeft getroffen is het mogelijk dat er zich
beveiligingsincidenten voordoen die een inbreuk in verband met de persoonsgegevens met zich
meebrengen. Indien zulks het geval is zal Crystal Care een dergelijk incident zo spoedig mogelijk
nadat zij er kennis van heeft genomen aan de Autoriteit Persoonsgegevens melden, tenzij het niet
waarschijnlijk is dat de inbreuk in verband met persoonsgegevens een risico inhoudt voor de rechten
en vrijheden van de betrokkenen. Indien het incident daarnaast waarschijnlijk een hoger risico
inhoudt voor de rechten en vrijheden van natuurlijke personen deelt Crystal Care ook de
betrokkenen de inbreuk zo spoedig mogelijk mee. In dat kader heeft Crystal Care een procedure
datalekken opgesteld. Crystal Care documenteert alle inbreuken in verband met de
persoonsgegevens, met inbegrip van de feiten en de gevolgen daarvan en de getroffen corrigerende
maatregelen.</p>
<h2>12. Verwerkingsregister</h2>
<p>Crystal Care houdt een schriftelijk register van de verwerkingsactiviteiten bij die onder haar
verantwoordelijkheid plaatsvinden. Dat register bevat in ieder geval de volgende gegevens:</p>
<ul><li>de naam en contactgegevens van Crystal Care en van de Functionaris Gegevensbescherming;</li>
<li>de doelen van de verwerking;</li>
<li>een beschrijving van het soort persoonsgegevens en de daarbij horende betrokkenen;</li>
<li>een beschrijving van de (categorieën) ontvangers van de persoonsgegevens;</li>
<li>een beschrijving van de doorgifte van persoonsgegevens aan een derde land of
internationale organisatie;</li>
<li>indien mogelijk, de termijnen waarin de verschillende persoonsgegevens moeten worden
gewist;</li>
<li>een algemene beschrijving van de getroffen technische en organisatorische
beveiligingsmaatregelen.</li></ul>
<h2>13. Functionaris Gegevensbescherming</h2>
<p>Crystal Care heeft vrijwillige een Functionaris Gegevensbescherming (FG) aangesteld. De FG is
betrokken bij alle aangelegenheden die verband houden met de bescherming van persoonsgegevens
binnen Crystal Care. De taken van de functionaris zijn informeren, adviseren, toezicht houden,
bewustwording creëren, en optreden als contactpersoon van de Autoriteit Persoonsgegevens en
betrokkenen. Alhoewel Crystal Care een FG heeft aangesteld blijft zij zelfstandig verantwoordelijk
voor de naleving van de geldende (privacy) wet- en regelgeving.</p>
<p>Betrokkenen kunnen met de FG contact opnemen over alle aangelegenheden die verband houden
met de verwerking van hun persoonsgegevens en met de uitoefening van hun rechten. Voor de
contactgegevens verwijzen we naar artikel 6.</p>
<h2>14. Doorgifte van persoonsgegevens (buiten NL, buiten EU)</h2>
<p>Crystal Care verwerkt de persoonsgegevens in Nederland (of in een land in de Europese Unie) en
geeft ze niet door aan landen of internationale organisaties buiten de Europese Unie.</p>
<h2>15. Klachten</h2>
<p>Indien de betrokkene van mening is dat Crystal Care in verband met de verwerking van zijn/ haar
persoonsgegevens inbreuk maakt op de geldende wet- en regelgeving dan wel op dit reglement, kan
hij/zij zich wenden tot de functionaris gegevensbescherming van Crystal Care. Indien dit voor
betrokkene niet leidt tot een acceptabel resultaat, kan hij/zij zich wenden tot:</p>
<ul><li>De klachtenfunctionaris inzake de klachtenprocedure van Crystal Care;</li>
<li>De Autoriteit Persoonsgegevens. De betrokkene kan de Autoriteit Persoonsgegevens verzoeken
een onderzoek in te stellen;</li></ul>
<h2>16. Slotbepalingen</h2>
<p>Crystal Care kan dit reglement wijzigen.</p>
<p>Dit reglement treedt per 01-03-2020 in werking bij Crystal Care. Eventuele voorgaande
privacyreglementen komen hierdoor te vervallen.</p>
<p>Dit reglement is gepubliceerd op de website van Crystal Care: <a href="/">CrystalCareZorg.nl</a>.</p>
<p>Download <a href="/assets/documents/Privacyreglement_Crystal_Care_20200301.pdf">hier</a> de pdf versie van het reglement</p>
        `;
  }

  private setUitslutingsCriteria(): void {
    this.uitsluitingsCriteria.title = `<h1>Uitsluitingscriteria</h1>`;
    this.uitsluitingsCriteria.body = `
            <p>Crystal Care kiest er bewust voor om geen zorg te verlenen/te bieden aan  cliënten:</p>
            <ul>
                <li>met psychiatrische problematiek;</li>
                <li>met verslavingsproblematiek;</li>
                <li>met agressieproblematiek;</li>
                <li>cliënten die vallen onder Wet zorg en Dwang;</li>
                <li>met een MSVT-indicatie;</li>
                <li>die hun huis niet meer als veilig ervaren;</li>
                <li>suïcidaal zijn;</li>
                <li>met claimend gedrag.</li>
            </ul>
            <p>Cliënten aan wie op grond van deze uitsluitingscriteria geen zorg geleverd kan worden, worden hierover geïnformeerd.</p>
        `;
  }

  private setKlachtenregeling(): void {
    this.klachtenregeling.title = `<h1>Klachtenreglement</h1>`;
    this.klachtenregeling.body = `

          <p>De onafhankelijke klachtenregeling voor cliënten (en hun vertegenwoordigers en
          nabestaanden) van zelfstandige zorgaanbieders en zorgaanbieders in kleinschalige
          zorginstellingen.
          Op grond van de Wet kwaliteit klachten en geschillen zorg (Wkkgz) is iedere zorgaanbieder
          verplicht een klachtenregeling te treffen voor een effectieve en laagdrempelige opvang en
          afhandeling van hem betreffende klachten (artikel 13, eerste lid, Wkkgz). De klachtenregeling
          is gericht op het bereiken van een voor de klager en de zorgaanbieder bevredigende
          oplossing.
          Klachtenportaal Zorg is een onafhankelijk orgaan dat klachten behandelt die worden
          ingediend over de geleverde zorg van een zorgaanbieder die is aangesloten bij
          Klachtenportaal Zorg.
          Deze klachtenregeling voldoet aan de gestelde eisen in de Wkkgz en het Uitvoeringsbesluit
          Wkkgz.
          </p>
      <h2>Artikel 1 Begripsbepaling</h2>
      <ol type="1">
        <li>
          In deze klachtenregeling wordt verstaan onder:
          <ol type="a">
          <li>
              Wkkgz: de Wet kwaliteit, klachten en geschillen zorg van 7 oktober 2015, in werking
              getreden op 1 januari 2016.
          </li>
          <li>
            Uitvoeringsbesluit Wkkgz: het Besluit houdende vaststelling van nadere regels ter
            uitvoering van de Wet kwaliteit, klachten en geschillen zorg, in werking getreden op 1
            januari 2016.
          </li>
          <li>
              Zorgaanbieder: zorgaanbieder als bedoeld in artikel 1, eerste lid, van de wet.
          </li>
          <li>
              Cliënt: een natuurlijke persoon die zorg vraagt of aan wie zorg wordt verleend.
          </li>
          <li>
              Klager: de cliënt, diens vertegenwoordiger of een nabestaande van cliënt.
          </li>
          <li>
              Klacht: een schriftelijke uiting van ongenoegen over een gedraging, waaronder mede
              begrepen het handelen of nalaten, jegens een cliënt in het kader van de
              zorgverlening door de zorgaanbieder of een schriftelijke
              uiting van ongenoegen over de weigering van een zorgaanbieder om een persoon in
              het kader van de zorgverlening als vertegenwoordiger van een patiënt te
              beschouwen.
          </li>
          <li>
              Klachtenfunctionaris: degene die geschikt is geacht en is aangewezen om een klager
              op diens verzoek van advies te dienen met betrekking tot de indiening van een klacht
              en een klager bij te staan bij het formuleren van de klacht en de mogelijkheden te
              onderzoeken om tot een oplossing van de klacht te komen.</li>
          </li>
      </ol>
        </li>
      </ol>

      <h2>Artikel 2 Informele oplossing en indienen van een klacht </h2>
      <ol type="1">
        <li>De cliënt of diens vertegenwoordiger wendt zich bij ongenoegen bij voorkeur eerst tot
        de zorgaanbieder om tot een informele oplossing te komen. Hierbij kan
        ondersteuning worden geboden door de Klachtenfunctionaris.</li>
        <li>De cliënt of diens vertegenwoordiger wordt in de gelegenheid gesteld om diens
        ongenoegen te bespreken. Andere personen worden betrokken bij dit gesprek als dit
        bevorderlijk is voor de oplossing van de onvrede en de cliënt of diens
        vertegenwoordiger daartegen geen bezwaar maakt.</li>
        <li>Wanneer het gesprek niet tot een afdoende oplossing van het ongenoegen leidt,
        wordt de cliënt of diens vertegenwoordiger gewezen op deze klachtenregeling.
        Daarbij wordt ook gewezen op de mogelijkheid advies in te winnen bij de
        Klachtenfunctionaris. De Klachtenfunctionaris en de cliënt of diens vertegenwoordiger
        proberen in samenspraak met elkaar op informele wijze tot een bevredigende
        oplossing te komen.</li>
        <li>De cliënt of diens vertegenwoordiger kan, indien het ongenoegen niet is
        weggenomen, dan wel indien zich de situatie voordoet dat cliënt of diens
        vertegenwoordiger zich niet rechtstreeks tot de zorgaanbieder wil wenden, een klacht
        indienen door op <a href="www.klachtenportaalzorg.nl">www.klachtenportaalzorg.nl</a> het klachtenformulier in te vullen, of
        contact op te nemen met Klachtenportaal Zorg via <a href="mailto:info@klachtenportaalzorg.nl">info@klachtenportaalzorg.nl</a>. Bij
        het indienen van een klacht kan de cliënt of diens vertegenwoordiger beroep doen op
        ondersteuning van de Klachtenfunctionaris. Deze ondersteuning bestaat uit het door
        de Klachtenfunctionaris bijstaan van de klager bij het formuleren van de klacht.</li>
        <li>De datum waarop de klacht is ingediend, geldt als de aanvangsdatum van de
        behandeltermijn, als vermeld in artikel 7 van deze klachtenregeling.</li>
        <li>Indien een klacht wordt ingediend door een vertegenwoordiger of nabestaande van
        de cliënt, kan Klachtenportaal Zorg verlangen dat stukken worden overgelegd,
        waaruit blijkt dat de klager in die hoedanigheid handelt.</li>
      </ol>
      <h2>Artikel 3 Buiten behandeling laten klacht</h2>
      <ol type="1">
        <li>Een klacht wordt alleen in behandeling genomen door de Klachtenfunctionaris als deze schriftelijk is ingediend en is voorzien
         van:
            <ol type="a">
                <li>naam, adres, woonplaats en telefoonnummer en eventueel een e-mailadres van de klager;</li>
                <li>een datering;</li>
                <li>een duidelijke omschrijving van de zorgaanbieder(s) op wie de klacht betrekking heeft;</li>
                <li>een duidelijke omschrijving van de inhoud van de klacht;</li>
                <li>een duidelijke omschrijving van de feiten en omstandigheden waarop de klacht betrekking heeft.</li>
            </ol>
        </li>
        <li>
            Voor behandeling van het geschil moet de klacht in het Nederlands zijn gesteld.
        </li>
        <li>De klacht wordt niet in behandeling genomen als:
            <ol type="a">
                <li>
                    de klacht niet aan de vereisten van het eerste lid voldoet en de klager in devgelegenheid is gesteld om binnen twee
                    weken de gegevens aan te vullen;
                </li>
                <li>
                    de vertegenwoordiger of nabestaande van de cliënt niet de gevraagde stukken, als bedoeld in artikel 2, zesde lid, heeft
                    overgelegd en in de gelegenheid is gesteld om binnen twee weken de gevraagde stukken te;
                </li>
                <li>
                    de klacht is ingediend door een ander dan genoemd overleggen in artikel 1, eerste lid, onder e;
                </li>
                <li>
                    de zorgaanbieder niet is aangesloten bij Klachtenportaal Zorg;
                </li>
                <li>
                    eerder een klacht over dezelfde gebeurtenis is behandeld en zich daarna geen nieuwe feiten of omstandigheden hebben
                    voorgedaan;
                </li>
                <li>
                    de gebeurtenissen die onderwerp zijn van de klacht zich meer dan één jaar voor de datum van de klacht, hebben
                    voorgedaan;
                </li>
                <li>
                    de gebeurtenissen die onderwerp van de klacht zijn, hebben plaatsgevonden in de periode dat de zorgaanbieder niet was
                    aangesloten bij Klachtenportaal Zorg. De klacht kan wel in behandeling worden genomen wanneer tussen Klachtenportaal
                    Zorg en de zorgaanbieder alsnog een overeenkomst is gesloten, waarbij is afgesproken dat de klacht toch kan worden
                    behandeld.
                </li>
            </ol>
        </li>
      </ol>
      <h2>Artikel 4 Overdracht en gecombineerde behandeling klachten</h2>
      <ol type="1">
        <li>Indien de klacht op anderen dan de zorgaanbieder betrekking heeft, draagt de
        zorgaanbieder er zorg voor dat de behandeling op zorgvuldige wijze wordt
        overdragen aan degene op wiens handelen de klacht betrekking heeft, tenzij de
        klager daarvoor geen toestemming geeft.</li>
        <li>Indien een klacht betrekking heeft op zorg die wordt verleend in samenhang met
        zorg, ondersteuning of hulp die een ander verleent en de klager heeft laten weten dat
        hij ook bij die ander een klacht heeft ingediend, zal de Klachtenfunctionaris met de
        Klachtenfunctionaris van die andere zorgaanbieder overleggen hoe tot een
        gezamenlijke behandeling van de klacht kan worden gekomen zodat inhoudelijk recht
        wordt gedaan aan de samenhang tussen de verleende zorg, tenzij de klager daarvoor
        geen toestemming geeft.</li>
      </ol>
      <h2>Artikel 5 Ontvangstbevestiging</h2>
      <p>Klachtenportaal Zorg stuurt binnen vijf werkdagen na ontvangst van de klacht een
        bevestiging van de ontvangst van de klacht aan de klager en neemt telefonisch of per e-mail
        contact op met klager. </p>
      <h2>Artikel 6 Taken en verantwoordelijkheden van de Klachtenfunctionaris</h2>
      <ol type="1">
        <li>De Klachtenfunctionaris heeft de volgende taken en verantwoordelijkheden:
            <ol type="a">
              <li>het in behandeling nemen van de klacht en het maken van een eerste beoordeling;</li>
              <li>het doen van zorgvuldig onderzoek naar de klacht en de mogelijkheden tot een
              bevredigende oplossing te komen;</li>
              <li>de cliënt of diens vertegenwoordiger bij te staan bij het formuleren van de klacht;</li>
              <li>de klager op diens verzoek te informeren en te adviseren over de mogelijkheden een
              klacht in te dienen;</li>
              <li>de zorgaanbieder in kennis te stellen van de klacht.</li>
              <li>de klager op de hoogte te houden van de voortgang van de behandeling van de
              klacht;</li>
              <li>het zorgdragen voor verslaglegging van het proces van klachtbehandeling en de
              resultaten;</li>
              <li>verwijzing naar de Geschilleninstantie Geschillenportaal Zorg, als de klacht niet is
              opgelost en de klager de procedure wenst voort te zetten.</li>
            </ol>
        </li>
        <li>Bij de uitoefening van de taak komt de Klachtenfunctionaris de bevoegdheid toe om
        al hetgeen te doen dat redelijkerwijs noodzakelijk is voor een goede begeleiding van
        het proces bij de behandeling van een klacht.</li>
        <li>De Klachtenfunctionaris zal in het achter de klacht liggende behandeldossier geen
        inzage hebben zonder voorafgaande schriftelijke toestemming van de klager.</li>
        <li>De Klachtenfunctionaris werkt op basis van het principe van hoor en wederhoor van
        klager en zorgaanbieder. De zorgaanbieder is daarbij gehouden tot volledige
        medewerking aan de behandeling van de klacht.</li>
        <li>De Klachtenfunctionaris dient zich onafhankelijk en onpartijdig op te stellen tegenover
        de klager en de zorgaanbieder. De Klachtenfunctionaris dient zich dan ook te
        onthouden van gedragingen op grond waarvan de verdenking kan ontstaan van (de
        schijn van) partijdigheid of het niet op onafhankelijke wijze uitoefenen van de functie.</li>
        <li>Indien de Klachtenfunctionaris betrokken is (geweest) bij een aangelegenheid waarop
        de klacht betrekking heeft, dan wel anderszins vanwege omstandigheden niet op
        onafhankelijke en onpartijdige wijze de functie in een individueel geval kan
        uitoefenen, dient dit zo snel als mogelijk door de Klachtenfunctionaris bij de
        zorgaanbieder te worden gemeld. In dat geval zal een andere Klachtenfunctionaris
        worden aangewezen.</li>
        <li>. De zorgaanbieder waarborgt dat de Klachtenfunctionaris zijn functie onafhankelijk
        kan uitvoeren en benadeelt de Klachtenfunctionaris niet wegens de wijze waarop de
        functie wordt uitgeoefend.</li>
      </ol>
      <h2>Artikel 7 Termijnen</h2>
      <ol type="1">
        <li>De klager ontvangt na afloop van de klachtbehandeling, zo spoedig mogelijk, doch
        uiterlijk binnen zes weken na indiening van de klacht, een brief van de zorgaanbieder
        waarin met redenen is omkleed tot welke uitkomst het onderzoek van de klacht heeft
        geleid, welke beslissingen er over en naar aanleiding van de klacht zijn genomen en
        binnen welke termijn de maatregelen waartoe is besloten, gerealiseerd zullen zijn.</li>
        <li>Indien het vereiste zorgvuldige onderzoek van een klacht daartoe naar het oordeel
        van de zorgaanbieder noodzaakt, kan de zorgaanbieder de in het eerste lid
        genoemde termijn met ten hoogste vier weken verlengen. De zorgaanbieder doet
        daarvan voor het verstrijken van de termijn schriftelijk mededeling aan de klager.</li>
        <li>Wanneer zowel de klager als de zorgaanbieder daarmee schriftelijk instemmen, kan
        de behandeltermijn verder worden verlengd. Dit kan bijvoorbeeld in het geval van
        keuze voor een mediationtraject.</li>
        <li>De zorgaanbieder informeert klager in zijn schriftelijk oordeel van de klacht over de
        mogelijkheid voor klager de klacht voor te leggen aan de Geschilleninstantie
        Geschillenportaal Zorg.</li>
      </ol>
      <h2>Artikel 8 Intrekking van de klacht</h2>
      <p>Een klacht wordt niet verder behandeld, indien de klager de klacht schriftelijk intrekt. Als de
      klacht om deze reden niet verder wordt behandeld deelt de Klachtenfunctionaris dit mee aan
      de zorgaanbieder en de klager.</p>
      <h2>Artikel 9 Bekendmaking Klachtenregeling</h2>
      <p>De zorgaanbieder draagt er zorg voor dat deze klachtenregeling bij cliënten en diens
      vertegenwoordigers wordt bekendgemaakt, onder andere door vermelding op de eigen
      website, het verstrekken van folders en het mondeling wijzen op het bestaan van de
      klachtenregeling in geval van een klacht.</p>
      <h2>Artikel 10 Kosten</h2>
      <p>De klager is voor de klachtbehandeling zoals neergelegd in deze klachtenregeling geen
      kosten verschuldigd.</p>
      <h2>Artikel 11 Geheimhouding</h2>
      <p>De bij de klachtbehandeling betrokken personen zijn verplicht tot geheimhouding ten aanzien
      van verkregen (persoons-)gegevens waarvan het vertrouwelijke karakter bekend is dan wel
      redelijkerwijs bekend geacht moet worden te zijn. Dit betekent dat deze gegevens,
      behoudens de uit de wet voortvloeiende meldplicht, niet aan derden mogen worden
      verstrekt.</p>
      <h2>Artikel 12 Verslaglegging</h2>
      <ol type="1">
        <li>Het Klachtenportaal Zorg brengt jaarlijks voor 1 april van het kalenderjaar volgend op
        het verslagjaar een geanonimiseerd verslag uit van de werkzaamheden van de
        Klachtenfunctionarissen.</li>
        <li>Beschreven worden het aantal en de aard van de klachten, de door de
        Klachtenfunctionarissen behandelde klachten, de strekking van de oordelen en
        eventuele aanbevelingen.</li>
        <li>Het jaarverslag wordt gepubliceerd en ingediend bij de IGZ.</li>
        <li>Binnen 10 dagen na vaststelling van het jaarverslag brengt het Klachtenportaal Zorg
        bericht uit op haar website.</li>
        <li>Het jaarverslag is op te vragen door cliëntenraden en wordt ter inzage gelegd bij
        zorgaanbieders die daarom verzoeken. Van de ter inzage legging wordt mededeling
        gedaan.</li>
      </ol>
      <h2>Artikel 13 Bewaarplicht</h2>
      <p>Klachtenportaal Zorg bewaart alle stukken met betrekking tot een klacht en de behandeling
      daarvan in een dossier, dat maximaal twee jaar wordt bewaard nadat de klacht is afgerond,
      tenzij er zwaarwegende redenen zijn om deze stukken langer te bewaren.</p>
      <h2>Artikel 14 Inwerkingtreding</h2>
      <p>De Klachtenregeling is in overeenstemming met representatief te achten organisaties van
      cliënten vastgesteld door Klachtenportaal Zorg en treedt per 1 februari 2017 in werking en
      werkt terug tot en met 1 januari 2017.</p>
      <br><br><br><p>Download <a href="./assets/documents/Klachtenreglement-KPZ-Wkkgz.pdf">hier</a> het reglement in pdf formaat.</p>`;
  }

  public addressToUrl(): string {
    const result = `${this.address.street} ${this.address.zip} ${this.address.city}`;
    return result.replace(/ /g, '%20');
  }
}
