import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';

import {ToolbarComponent} from './toolbar/toolbar.component';
import {RoutingModule} from './app.routes';
import {PageComponent} from './page/page.component';
import {FooterComponent} from './footer/footer.component';
import {HomeComponent} from './home/home.component';
import {ContentService} from './services/content.service';

import {VacanciesComponent} from './vacancies/vacancies.component';
import {VacancyComponent} from './vacancies/vacancy/vacancy.component';
import {ContactComponent} from './contact/contact.component';
import {SeoService} from './services/seo.service';
import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
    declarations: [
        AppComponent,
        ToolbarComponent,
        PageComponent,
        FooterComponent,
        HomeComponent,
        VacanciesComponent,
        VacancyComponent,
        ContactComponent,
        PageNotFoundComponent
    ],
    imports: [
        RoutingModule,
        BrowserModule.withServerTransition({appId: 'crystal-care'}),
        BrowserAnimationsModule,
        MatToolbarModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatGridListModule,
        MatExpansionModule,
        NgxJsonLdModule
    ],
    providers: [ContentService, SeoService],
    bootstrap: [AppComponent]
})
export class AppModule {}
