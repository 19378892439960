import {Component, OnInit} from '@angular/core';
import {SeoService} from '../services/seo.service';
import {ContentService} from '../services/content.service';

@Component({
    selector: 'cc-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    constructor(private seoService: SeoService, public contentService: ContentService) {
    }

    ngOnInit() {
        this.seoService.setTitle('Contact | Crystal Care');
        this.seoService.generateTags({
            description: `‘Zorgt voor u’ staat bij Crystal Care centraal! Bij kunnen dragen aan deze maatschappelijke
                           opdracht inspireert ons. Onze medewerkers zijn warm en betrokken, zij werken vanuit het hart
                           en hebben liefde voor mensen.`,
            'og:description': `Zorgt voor u’ staat bij Crystal Care centraal! Bij kunnen dragen aan deze maatschappelijke
                           opdracht inspireert ons. Onze medewerkers zijn warm en betrokken, zij werken vanuit het hart
                           en hebben liefde voor mensen.`,
            'og:image': 'http://crystalcarezorg.nl/assets/images/crystalcare-banner-image-small.webp',
            'og:url': 'http://crystalcarezorg.nl',
            'og:title': 'Thuiszorg Crystal Care',
            'og:locale': 'nl_NL',
            'og:type': 'website',
            'twitter:card': 'summary',
            'twitter:description': `‘Zorgt voor u’ staat bij Crystal Care centraal! Bij kunnen dragen aan deze maatschappelijke
                           opdracht inspireert ons. Onze medewerkers zijn warm en betrokken, zij werken vanuit het hart
                           en hebben liefde voor mensen.`,
            'twitter:title': 'Thuiszorg Crystal Care',
            'twitter:image': 'http://crystalcarezorg.nl/assets/images/crystalcare-banner-image-small.webp'
        });
    }

}
