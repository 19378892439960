import {Component, Input, OnInit} from '@angular/core';

interface Content {
    title: string;
    subtitle: string;
    body: string;
    image: string;
}

@Component({
  selector: 'cc-vacancy',
  templateUrl: './vacancy.component.html',
  styleUrls: ['./vacancy.component.scss']
})
export class VacancyComponent implements OnInit {
  @Input() content: Content;
  constructor() { }

  ngOnInit() {
  }

}
